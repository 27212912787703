import React, { useEffect, useState } from "react";
import { Button, Box } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useLocation } from 'react-router-dom';
import CreateUserUnauthenticatedForm from '../forms/CreateUserFormUnauthenticated'
import CreateUserAndOfferUnauthenticatedForm from '../forms/CreateUserAndOfferFormUnauthenticated'

import ErrorBoundary from '../components/ErrorBoundary';


enum FormState {
  Deciding,
  BusinessOwner,
  PropertyProfessional,
  Done
}

enum FormState_CanGoBack {
  CanGoBack,
  CantGoBack
}

const GetSignedUp: React.FC = () => {
  
  // const handleOwnerSignup = () => {
  //   setCurrentFormState(FormState.BusinessOwner)
  // }

  const handleReferralSignup = () => {
    // Logic for referral-only signup
    console.log('Signing up for referrals only');
  }

  const formIsDone = () => {
    setCurrentFormState_CanGoBack(FormState_CanGoBack.CantGoBack);
  }


  const [currentFormState, setCurrentFormState] = useState(FormState.Deciding);
  const [currentFormState_CanGoBack, setCurrentFormState_CanGoBack,] = useState(FormState_CanGoBack.CanGoBack);

  return (
    <ErrorBoundary>
    <div className="signup-container" style={{ padding: '10px' }}>
      {currentFormState === FormState.BusinessOwner ? (
        <>
          {currentFormState_CanGoBack == FormState_CanGoBack.CanGoBack && (
            <Button onClick={() => setCurrentFormState(FormState.Deciding)}>
              Back 
            </Button>
          )}

          <CreateUserAndOfferUnauthenticatedForm onDone={formIsDone}  />
        </>
       ) : currentFormState === FormState.PropertyProfessional ? (
        <>

          {currentFormState_CanGoBack == FormState_CanGoBack.CanGoBack && (
            <Button onClick={() => setCurrentFormState(FormState.Deciding)}>
              Back 
            </Button>
          )}
          
          <CreateUserUnauthenticatedForm onDone={formIsDone} />
        </>
      ) :  (
        <>
          <h2>How do you want to sign up?</h2>

          <div className="signup-options">
            <h3>As a Business Owner</h3>
            <ul>
              <li>I am the owner of a business and I want to list it on the In House Property Hub</li>
              <li>I will also be able to get paid for referring customers to other businesses</li>
            </ul>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button 
                variant="contained" 
                color="secondary" 
                sx={{width: {xs: '80%', sm: '50%'}}} 
                onClick={() => setCurrentFormState(FormState.BusinessOwner)} >
                Sign up As Business Owner <NavigateNextIcon/>
              </Button>
            </Box>

            <h3>As a Property Professional</h3>
            <ul>
              <li>I am a property professional and I want to get paid for referring customers to other businesses</li>       
            </ul>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="secondary" sx={{width: {xs: '80%', sm: '50%'}}} onClick={() => setCurrentFormState(FormState.PropertyProfessional)} >
              Sign up As Property Professional<NavigateNextIcon/>
            </Button>
            </Box>

          </div>
        </>
      )}
    </div>
    </ErrorBoundary>
  );
};

export default GetSignedUp;
