import React from 'react';
import { Typography, Button } from '@mui/material';
import { OfferWithScope_UserWithoutId } from '../helpers/DataStructures';
import { DisplayOfferInGridBody } from './DisplayOfferInGrid'; // Import the existing component
import { translateFromTechnicalToEnglish } from '../helpers/Language';

interface DisplayOfferInGridWrapperProps {
  offer: OfferWithScope_UserWithoutId;
  onActionButtonClick: (offer: OfferWithScope_UserWithoutId) => void;
}

const DisplayOfferInGridWrapper: React.FC<DisplayOfferInGridWrapperProps> = ({ offer, onActionButtonClick }) => {
  const { user, location, offeringFixedCashAmount } = offer;

  const getCompanyLogo = (companyName: string| undefined) => {

    if (companyName === undefined) {
        return undefined;
    }

    else if (companyName === "Churchwood Stanley") {
        return <img src='logos/churchwoodstanley.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Patrick James") {
      return <img src='logos/patrick james.png' alt="Company Logo" width='100%'/>;
  }
    

    else if (companyName === "Trenter Bacon") {
      return <img src='logos/trenter bacon.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Julien Lawrence Surveying") {
      return <img src='logos/jls.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Weston and Edwards") {
      return <img src='logos/weston and edwards.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Weston and Edwards") {
      return <img src='logos/weston and edwards.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Schofield Removals") {
      return <img src='logos/schofield removals.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Neil Marsden Associates") {
      return <img src='logos/neil marsden.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Colchester Timber and Damp") {
      return <img src='logos/colchester timber and damp.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "TLC Environmental Services Ltd") {
      return <img src='logos/tlc environmental services.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Suffolk Asbestos Solutions") {
      return <img src='logos/suffolk asbestos solutions.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Creasey Drainage") {
      return <img src='logos/creasey drainage.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Mersea Drains") {
      return <img src='logos/mersea drains.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Real Clean") {
      return <img src='logos/real estate cleaning.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Holistic Cleaning") {
      return <img src='logos/holistic cleaning services.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Rhodda & Co") {
      return <img src='logos/rhodda and co.png' alt="Company Logo" width='100%'/>;
    }

    else if (companyName === "Harwich Village") {
      return <img src='logos/harwich village.png' alt="Company Logo" width='100%'/>;
    }
    
    return undefined;
  }


  return (
    <div>
      <DisplayOfferInGridBody        
        Company={user.companyName || ''}
        Profession={translateFromTechnicalToEnglish(user.profession)}
        CompanyLogo={getCompanyLogo(user.companyName)}
        Location= {location === 'Not Specified' ? '' : location}
        Offer={<>{offeringFixedCashAmount > 0 && `£${offeringFixedCashAmount}`}</>}
        ActionButton={(
          <>
            {!offer.user.isMe && (
              <Button
                color="secondary"
                variant="contained"
                size="large"
                onClick={() => onActionButtonClick(offer)}
              >
                Go
              </Button>
            )}
          </>
        )}
      />
    </div>
  );
};

export default DisplayOfferInGridWrapper;
