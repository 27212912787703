import React from 'react';
import { Card, CardContent, Typography, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { translateFromStatusToShortTextForReferrer, translateFromStatusToSentenceReferrer } from '../helpers/Language';

import { ReferralWithScope_UserWithoutId } from '../helpers/DataStructures';

interface UserReferralProps {
  referral: ReferralWithScope_UserWithoutId;}

const DisplayReferralOut: React.FC<UserReferralProps> = ({ referral }) => {
  return (
    <>
            <Card>
              <CardContent sx={{textAlign: 'center'}}>
                <Typography variant='h5'>
                  Referral to {referral.offer.user.givenName} {referral.offer.user.familyName} 
                  {referral.offer.user.companyName && ( ` at ${referral.offer.user.companyName}`)}
                </Typography>
                <Typography variant='h6'>
                  {referral.offer.location}
                </Typography>
                <Typography variant='bigPrimary' >
                  £{referral.offer.offeringFixedCashAmount}
                </Typography>

                <Card sx={{borderWidth: '0px', backgroundColor:'#222'}}>
                    <CardContent sx={{textAlign: 'center'}}>
                        <Typography variant='h5'>
                        {referral.theReferralName}
                        </Typography>
                        <Typography variant='h6'>
                        {referral.theReferralContact}
                        </Typography>
                        {referral.theReferralDetails && ( 
                        <Typography variant='body2' >
                        {referral.theReferralDetails}
                        </Typography>
                        )}
                        <Typography variant='bigPrimary' >
                        {translateFromStatusToShortTextForReferrer(referral.status)}
                        </Typography>
                        
                        <Typography variant='body2' sx={{ mb: 1 }}>
                          {translateFromStatusToSentenceReferrer(referral.status)}
                          
                        </Typography>

                    </CardContent>
                </Card>


              </CardContent>
            </Card>

            

    </>
  );
};

export default DisplayReferralOut;
