// src/components/MyOffersDataTable.tsx
import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Container, Tab, Tabs } from '@mui/material';

import { APIUnauthenticatedService, APIUnauthenticatedServiceContext  } from '../helpers/APICallsUnauthenticated';
import { APIAuthenticatedService, APIAuthenticatedServiceContext  } from '../helpers/APICallsAuthenticated';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import { motion, AnimatePresence } from "framer-motion";

import Busy from '../components/Busy';

import ErrorBoundary from '../components/ErrorBoundary';

import { assign, Machine, MachineOptions, GuardPredicate, MachineConfig } from 'xstate';
import { useMachine } from '@xstate/react';

import { useConfigInfoService  } from '../helpers/ConfigInfo';

interface ReferralsAppParentProps {
    showIfLoggedOut: ReactNode;
    showIfLoggedIn: ReactNode;
}

const ReferralsAppParent: React.FC<ReferralsAppParentProps> = ({showIfLoggedOut, showIfLoggedIn}) => {

  const [apiService, setAPIService] = useState<APIAuthenticatedService | null>(null);  
  const { getAccessTokenSilently, isAuthenticated, user, loginWithRedirect, getIdTokenClaims, logout  } = useAuth0();

  const [hasError, setHasError] = useState<any>(null);  
  
  const queryClient = useRef(new QueryClient());

  const [stateMachine, sendToStateMachine] = useMachine( apiServiceMachine );

  const configInfoServiceInstance = useConfigInfoService();

  useEffect(() => {
    if (isAuthenticated && apiService === null && user && user.sub) {     
        
        sendToStateMachine([{ type: ApiServiceEventTypes.LOG_IN, data: { apiServiceReady: false, loggedIn: isAuthenticated }, isError: false }]);

        (async () => {
          try {
            
            const _apiservice = APIAuthenticatedService.getInstance( getAccessTokenSilently, getIdTokenClaims, configInfoServiceInstance );
            await _apiservice.init();   
            
            console.log((await _apiservice.getApiInfo()).message);
            
            const idToken = await getIdTokenClaims();
          
            setAPIService(_apiservice);
            
          } catch (error: any) {
            
            if (error.message?.startsWith('Missing Refresh Token') || error.code === 'missing_refresh_token') {
              console.log('Missing Refresh Token');
              logout(); 
            }
            else if( error.message?.startsWith('Unknown or invalid refresh token')) {
              console.log('Unknown or invalid refresh token');
              logout();   
            } else {         
              setHasError(error);
            }
          }

        })();
    }
  }, [isAuthenticated, apiService, getAccessTokenSilently, user?.sub]);

  useEffect(() => {

    if (apiService !== null) {    
       
      sendToStateMachine([{ type: ApiServiceEventTypes.API_LOAD, data: { apiServiceReady: true, loggedIn: isAuthenticated }, isError: false }]);
    }

  }, [apiService]);


  if(hasError) return (    
    <Box sx={{textAlign:'left'}}>
      <h1>Error.... Please Refresh the web page</h1>
      <h3>{hasError.message? 'Error Message: ' + hasError.message : ''}</h3>
    </Box>
  );
 

  return (
    <>     
            <AnimatePresence mode='wait'>
            <motion.div key={stateMachine.value.toString()} style={{ width: '100%' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
            <QueryClientProvider client={queryClient.current}>
            <ErrorBoundary>
            <Container style={{maxWidth:'800px', padding: "0px" }} >

                  {stateMachine.matches(ApiServiceStateNames.NOT_AUTHENTICATED) && (
                            <APIUnauthenticatedServiceContext.Provider value={APIUnauthenticatedService.getInstance(configInfoServiceInstance)}>
                                {showIfLoggedOut}             
                            </APIUnauthenticatedServiceContext.Provider>
                    )                  
                  }
                  {stateMachine.matches(ApiServiceStateNames.AUTHENTICATED_LOADING) && <Busy message='Loading...' />   }
                  {stateMachine.matches(ApiServiceStateNames.AUTHENTICATED_SHOW_APP) && (                      
                          <APIAuthenticatedServiceContext.Provider value={apiService}>                              
                                {showIfLoggedIn}                
                          </APIAuthenticatedServiceContext.Provider>                                        
                  )}


            </Container>                
            </ErrorBoundary>  
            </QueryClientProvider>  
            </motion.div>          
            </AnimatePresence>
    </>
  );
};

export default ReferralsAppParent;


type ApiServiceContext = {
 
};

const initialApiServiceContext: ApiServiceContext = {
  apiServiceReady: false,
  loggedIn: false
};

enum ApiServiceStateNames {
  NOT_AUTHENTICATED = 'notAuthenticated',  
  AUTHENTICATED_LOADING = 'authenticatedLoading',
  AUTHENTICATED_SHOW_APP = 'authenticatedShowApp'
}

interface ApiServiceSchema {
  states: {
    [ApiServiceStateNames.NOT_AUTHENTICATED]: {};   
    [ApiServiceStateNames.AUTHENTICATED_LOADING]: {};
    [ApiServiceStateNames.AUTHENTICATED_SHOW_APP]: {};
  };
}

enum ApiServiceEventTypes { 
  API_LOAD = 'API_LOAD',
  LOG_IN = 'LOG_IN'
}

interface ApiServiceEvent {
  type: ApiServiceEventTypes;
  data?: { apiServiceReady: boolean; loggedIn: boolean }; // Make data optional
  isError: Boolean;
}

const apiServiceOptions: Partial<MachineOptions<ApiServiceContext, ApiServiceEvent>> = {
  
};

const isLoggedIn: GuardPredicate<ApiServiceContext, ApiServiceEvent> = {
  type: 'xstate.guard',
  name: 'isLoggedIn',
  predicate: (context, event) => event.data?.loggedIn ?? false
};


const isAPIServiceReady: GuardPredicate<ApiServiceContext, ApiServiceEvent> = {
  type: 'xstate.guard',
  name: 'isAPIServiceReady',
  predicate: (context, event) => event.data?.apiServiceReady ?? false
};


const apiServiceConfig: MachineConfig<ApiServiceContext, ApiServiceSchema, ApiServiceEvent> = {
  id: 'apiService',
  initial: ApiServiceStateNames.NOT_AUTHENTICATED,
  context: initialApiServiceContext,
  states: {
    notAuthenticated: {
      on: {
        LOG_IN: [
          { target: ApiServiceStateNames.AUTHENTICATED_LOADING, cond: isLoggedIn }          
        ],
        API_LOAD: [
          { target: ApiServiceStateNames.AUTHENTICATED_SHOW_APP, cond: isAPIServiceReady }
        ]
      },
      
    },   
    authenticatedLoading: {
      on: {       
        API_LOAD: [
          { target: ApiServiceStateNames.AUTHENTICATED_SHOW_APP, cond: isAPIServiceReady }
        ]
      },
    },   
    authenticatedShowApp: {
      
    },
  }
};

const apiServiceMachine = Machine<ApiServiceContext, ApiServiceSchema, ApiServiceEvent>(apiServiceConfig, apiServiceOptions);

