
import { useContext, createContext } from 'react';
import axios, {AxiosResponse} from 'axios';

import { ConfigInfoService } from './ConfigInfo';

import { 
  User_WithoutId, 
  UserCreationAsPropertyProfessionalAttributes,
  OfferWithScope_UserWithoutId,
  ReferralWithScope_UserWithoutId,
  OfferCreationAttributes_FromUser,
  ReferralCreationAttributes_FromUser,
  UserCreationAsBusinessOwnerAttributes,
    } 
  from './DataStructures';

export enum CacheKeys {  
  OFFERS_BY_PROFESSION,
}


export class APIUnauthenticatedService {
  private static instance: APIUnauthenticatedService;
  private API_PATH; 

  private constructor(configInfo: ConfigInfoService) { 
    
    this.API_PATH = configInfo.apiServer;
  }

  public static getInstance = (configInfo: ConfigInfoService) => {

    if (!APIUnauthenticatedService.instance) {
      APIUnauthenticatedService.instance = new APIUnauthenticatedService(configInfo);
    }
    return APIUnauthenticatedService.instance;

  }

  public sendMail = async (text: string, email: string, name: string) : Promise<{message:string}> => {
    try {
      const response = await axios.post<{ message: string }>(
        `${this.API_PATH}/sendmail`,
        { name, email, text },
        { timeout: 2000 }
      );
      return response.data;
    } catch (error: any) {
      // handle error as needed
      throw new Error(error.response?.data?.message || error.message);
    }
  }
  

  public getApiInfo= async (): Promise<{message:string}> => {
    const response = await axios.get<{message:string}>(`${this.API_PATH}/info`);
    return response.data;
  };

  public getOffersByProfession = async (profession: string): Promise<OfferWithScope_UserWithoutId[]> => {
    const response = await axios.get<OfferWithScope_UserWithoutId[]>(`${this.API_PATH}/offers/profession/${profession}`);
    return response.data;
  };  
  
  public createUser = async (userToCreate: UserCreationAsPropertyProfessionalAttributes): Promise<User_WithoutId> => {

    try {  
  
      const response = await axios.post<User_WithoutId, AxiosResponse<User_WithoutId>, UserCreationAsPropertyProfessionalAttributes>(`${this.API_PATH}/users/createAsPropertyProfessional`, userToCreate);
      return response.data;

    } 
    catch (error: any) {
      
      if (axios.isAxiosError(error)) {
        // Check if error response exists and has expected structure
        if (error.response && error.response.data && error.response.data.error) {
          throw new Error(error.response.data.error); // Use the server-provided error message
        } else {
          throw new Error("An unknown error occurred");
        }
      } else if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }    
    }
  };

  public createUserAsBusinessOwner = async (userToCreate: UserCreationAsBusinessOwnerAttributes): Promise<User_WithoutId> => {

    try {  
  
      const response = await axios.post<User_WithoutId, AxiosResponse<User_WithoutId>, UserCreationAsBusinessOwnerAttributes>(`${this.API_PATH}/users/createAsBusinessOwner`, userToCreate);
      return response.data;

    } 
    catch (error: any) {
      
      if (axios.isAxiosError(error)) {
        // Check if error response exists and has expected structure
        if (error.response && error.response.data && error.response.data.error) {
          throw new Error(error.response.data.error); // Use the server-provided error message
        } else {
          throw new Error("An unknown error occurred");
        }
      } else if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }    
    }
  };
}


export const APIUnauthenticatedServiceContext = createContext<APIUnauthenticatedService| null>(null);

export function useAPIUnauthenticatedService() {      

    const context = useContext(APIUnauthenticatedServiceContext);

    if (context === null) {
      throw new Error('useAPIUnauthenticatedService must be used within a APIUnauthenticatedServiceContext.Provider');
    }
    return context;

}