import React, {useState} from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, DialogActions, Dialog, DialogTitle, IconButton, DialogContent, Container, Card, CardHeader, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery, useTheme, Typography } from '@mui/material';
import { useAPIAuthenticatedService } from '../../helpers/APICallsAuthenticated';
import { ReferralWithScope_UserWithoutId, CacheKeys } from '../../helpers/DataStructures';

import DateTimeFormat from '../../helpers/DateTimeFormat';

import CloseIcon from '@mui/icons-material/Close';

import { InHouseCardHeader, InHouseCardContent } from '../../components/CustomMUI';

import { translateFromStatusToShortTextForReferrer} from '../../helpers/Language';

import DisplayReferralOut from '../../components/DisplayReferralOut'

  const ReferralsAppReferralsOut: React.FC = () => {

  const apiServiceInstance = useAPIAuthenticatedService();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState<ReferralWithScope_UserWithoutId | null>(null);


  const handleOpen = (referral: ReferralWithScope_UserWithoutId) => {
    setSelectedReferral(referral);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { isLoading, isError, data, error } = useQuery(
    [CacheKeys.REFERRALS_FROM_USER],
    () => apiServiceInstance.getUserReferralsOut(),
    { keepPreviousData: true }
  );

  return (
<>
    <Card variant="outlined" title='dd' style={{ margin: '0 auto' }}>
       
    <InHouseCardHeader title='Referrals Sent'  />  
    <InHouseCardContent style={{ textAlign: 'center' }} > 
            { 
              isLoading || data === undefined  ? 
              (
                  <></>
              ) : data && data?.length === 0 ?
              (
                  <Typography variant='body2'>No referrals sent yet</Typography>
              ) :
              (
                <TableContainer component={Paper}>
                <Table>
                  <TableHead>

                      {isMobile && 
                        <TableRow>
                          <TableCell sx={{ width: '25%' }}>To</TableCell>
                          <TableCell sx={{ width: '25%' }}>Sent</TableCell>
                          <TableCell sx={{ width: '25%' }}>Customer</TableCell> 
                          <TableCell sx={{ width: '25%' }}>Status</TableCell>
                         </TableRow>
                      }
                      {!isMobile && 
                        <TableRow>
                          <TableCell sx={{ width: '20%' }}>To</TableCell>
                          <TableCell sx={{ width: '20%' }}>Sent</TableCell>
                          <TableCell sx={{ width: '20%' }}>Customer</TableCell> 
                          <TableCell sx={{ width: '20%' }}>Status</TableCell>
                          <TableCell sx={{ width: '20%' }}></TableCell>
                        </TableRow>                       
                      }     

                  </TableHead>
                  <TableBody>
                    {data.map((referral: ReferralWithScope_UserWithoutId) => (
                      <TableRow key={referral.id} onClick={() => handleOpen(referral)} style={{ cursor: 'pointer' }}>

                        <TableCell>
                          {referral.offer.user.companyName
                            ? referral.offer.user.companyName
                            : `${referral.offer.user.givenName} ${referral.offer.user.familyName}`}
                        </TableCell>


                        <TableCell>{DateTimeFormat(referral.created)}</TableCell>                        
                        <TableCell>{referral.theReferralName}</TableCell>               
                        <TableCell>{translateFromStatusToShortTextForReferrer(referral.status)}</TableCell>
                        {!isMobile && (
                          <TableCell>
                            <Button>View</Button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                 
              </TableContainer>
              )
          }
                  
          </InHouseCardContent>
        </Card>

        { selectedReferral && (
          <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DisplayReferralOut referral={selectedReferral} />
          </DialogContent>

          

        </Dialog>
        )}
        </>
    
  );
};

export default ReferralsAppReferralsOut;
