import React, { useState } from 'react';
import { Card, Grid, Box, TextField, Button, CardContent } from '@mui/material';

import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { useAPIAuthenticatedService } from '../../helpers/APICallsAuthenticated';
import { InHouseCardHeader, InHouseCardContent } from '../../components/CustomMUI';
import { CacheKeys } from '../../helpers/DataStructures';

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const apiServiceInstance = useAPIAuthenticatedService();

  // Fetch the current user details
  const { data: currentUser } = useQuery(
    [CacheKeys.LOGGED_IN_USER],
    apiServiceInstance.getAuthenticatedUser
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Append the current user's name and company details to the message
    let augmentedMessage = message;
    if (currentUser) {
      augmentedMessage += `\n\nSent by: ${currentUser.givenName } ${currentUser.familyName}`;
      augmentedMessage += `\nCompany: ${currentUser.companyName ?? 'Not Provided'}`;
      augmentedMessage += `\nProfession ${currentUser.profession}`;
    }
    
    try {
      await apiServiceInstance.sendMail(augmentedMessage, email, name);
      setName('');
      setEmail('');
      setMessage('');
      toast.success('Message Sent');
    } catch (err) {
      toast.error('Could not send message');
    }
  };

  return (
    <Card>
      <InHouseCardHeader title="Company Details" />
      <InHouseCardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField 
                label="Name" 
                value={name} 
                onChange={(e) => setName(e.target.value)}
                fullWidth 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                label="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                fullWidth 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                label="Service Provider Details" 
                value={message} 
                onChange={(e) => setMessage(e.target.value)}
                multiline 
                rows={4} 
                fullWidth 
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Box mt={2}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary"
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </InHouseCardContent>
    </Card>
  );
};

export default Contact;
