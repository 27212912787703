import React, {useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { Container, TextField, Button, FormControl, FormHelperText,  InputLabel, Select, MenuItem, Card, CardContent, Typography, Grid, CardHeader } from '@mui/material';
import { toast} from 'react-toastify';
import { useAPIUnauthenticatedService  } from '../helpers/APICallsUnauthenticated';
import { InHouseCardHeader, InHouseCardContent } from '../components/CustomMUI';
import { UserCreationAsPropertyProfessionalAttributes, CacheKeys } from '../helpers/DataStructures';
import { motion, AnimatePresence } from "framer-motion"; 
import { useAuth0 } from '@auth0/auth0-react';

enum FormState {
    FillOutForm,
    TellUserAboutRedirect
  }

interface CreateUserUnauthenticatedFormProps {
    onDone: () => void;
}

const CreateUserUnauthenticatedForm: React.FC<CreateUserUnauthenticatedFormProps> = ({ onDone }) => {

    const [currentFormState, setCurrentFormState] = useState(FormState.FillOutForm);

    const [usersEmail, setUsersEmail] = useState("");

    const { loginWithRedirect } = useAuth0();

    const { handleSubmit, control, formState: { errors } } = useForm<UserCreationAsPropertyProfessionalAttributes>();
    const queryClient = useQueryClient();

    const apiServiceInstance = useAPIUnauthenticatedService();

//   const { isLoading, isError, data: data_loggedInUser, error } = useQuery(
//     [CacheKeys.LOGGED_IN_USER], 
//     async () => {
      
//         const user = await apiServiceInstance.getAuthenticatedUser();
//         return user;      
//     }
//   ); 

  const { mutateAsync, isLoading, isError, isSuccess, error } = 
    useMutation([CacheKeys.LOGGED_IN_USER], apiServiceInstance.createUser,
    {
        onSuccess: () => {
            queryClient.invalidateQueries([CacheKeys.LOGGED_IN_USER]);
            setCurrentFormState(FormState.TellUserAboutRedirect);

            setTimeout(() => {
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: 'signup',
                    login_hint: usersEmail, 
                  },
                  appState: {
                    returnTo: window.location.origin + '/dashboard',
                    redirectUri: window.location.origin + '/dashboard',
                  },
                });
              }, 2000);

        },
        onError: (error: any) => {
            toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
        }
    },
  );

  const onSubmit = async (data: UserCreationAsPropertyProfessionalAttributes) => {
    setUsersEmail(data.email);
    try {
      await mutateAsync(data);
    } catch (error) {
      console.error('Submission error:', error);
    }
  }; 

  return (

    <AnimatePresence mode="wait">
    {currentFormState === FormState.TellUserAboutRedirect? (
        <motion.div
            key="redirecting"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2.0}}
        >
          Redirecting you to create a password for {usersEmail}
        </motion.div>
      ) : (
        <motion.div
            key="form"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2.0}}
        >
        <Card variant="outlined"  style={{ margin: '0 auto' }}>
            <InHouseCardHeader title="Welcome to In House" />
            <InHouseCardContent>      
                <Typography variant="cardSubtitle" component="div" style={{ marginBottom: '1em' }}>
                    Please provide your details...
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                
                                name="givenName"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'First name is required', maxLength: { value: 255, message: 'First name should be less than 255 characters'} }}
                                render={({ field }) => <TextField {...field} label="First Name" error={Boolean(errors.givenName)} helperText={errors.givenName?.message} fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="familyName"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Last name is required', maxLength: { value: 255, message: 'Last name should be less than 255 characters'} }}
                                render={({ field }) => <TextField {...field} label="Last Name" error={Boolean(errors.familyName)} helperText={errors.familyName?.message} fullWidth/>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="email" // Specify the field name
                                control={control}
                                defaultValue=""
                                rules={{
                                required: 'Email is required',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: 'Invalid email format',
                                },
                                maxLength: { value: 255, message: 'Email should be less than 255 characters' },
                                }}
                                render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Email"
                                    error={Boolean(errors.email)}
                                    helperText={errors.email?.message}
                                    fullWidth
                                />
                                )}
                            />
                            </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name="profession"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Profession is required' }}
                                render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="profession-label">Profession</InputLabel>
                                    <Select
                                    {...field}
                                    labelId="profession-label"
                                    label="Profession"
                                    error={Boolean(errors.profession)}
                                    > 
                                    <MenuItem value="estate agent">Estate Agent</MenuItem>
                                    <MenuItem value="surveyor">Surveyor</MenuItem>
                                    <MenuItem value="conveyancer">Conveyencer</MenuItem>
                                    <MenuItem value="mortgage broker">Mortgage Broker</MenuItem>

                                    <MenuItem value="removal and storage">Removal and Storage</MenuItem>
                                    <MenuItem value="damp and timber">Damp and Timber</MenuItem>
                                    <MenuItem value="asbestos surveyors">Asbestos Surveyors</MenuItem>
                                    <MenuItem value="drain engineers">Drain Engineers</MenuItem>
                                    <MenuItem value="house cleaners">House Cleaners</MenuItem>                             


                                    </Select>
                                    {errors.profession && <FormHelperText error>{errors.profession.message}</FormHelperText>}
      
                                </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="companyName"
                                control={control}
                                defaultValue=""
                                
                                rules={{ maxLength: { value: 255, message: 'Company name should be less than 255 characters'} }}
                                render={({ field }) => <TextField {...field} label="Company Name" error={Boolean(errors.companyName)} helperText={errors.companyName?.message} fullWidth/>}
                            />
                        </Grid>

                        <Grid item xs={12}  sx={{textAlign:'center'}}>
                            <Button variant='contained' type="submit" disabled={isLoading}>Submit</Button>
                        </Grid>
                    </Grid>
                </form>
            </InHouseCardContent>
        </Card>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CreateUserUnauthenticatedForm
