
import { useContext, createContext } from 'react';
import axios, {AxiosResponse} from 'axios';
import AxiosService from './AxiosService';
import { IdToken } from '@auth0/auth0-react';

import { ConfigInfoService } from './ConfigInfo';

import { 
  User_WithoutId, 
  
  OfferWithScope_UserWithoutId,
  ReferralWithScope_UserWithoutId,
  OfferCreationAttributes_FromUser,
  ReferralCreationAttributes_FromUser,
    } 
  from './DataStructures';


export class APIAuthenticatedService {
  private static instance: APIAuthenticatedService;
  private API_PATH; 
  public axiosService: AxiosService;

  private constructor(getAccessTokenSilently: () => Promise<string>, getIdTokenClaims: () => Promise<IdToken | undefined>, configInfo: ConfigInfoService) {

    this.axiosService = AxiosService.getInstance(getAccessTokenSilently, getIdTokenClaims);      
    
    this.API_PATH = configInfo.apiServer;
  }

  public static getInstance = (getAccessTokenSilently: () => Promise<string>, getIdTokenClaims: () => Promise<IdToken | undefined>, configInfo: ConfigInfoService) => {
    if (!APIAuthenticatedService.instance) {
      APIAuthenticatedService.instance = new APIAuthenticatedService(getAccessTokenSilently, getIdTokenClaims, configInfo);
    }

    return APIAuthenticatedService.instance;
  }

  public init = async () => {

    await this.axiosService.init();
  }

  
  public sendMail = async (text: string, email: string, name: string) : Promise<{message:string}> => {
    try {
      const response = await axios.post<{ message: string }>(
        `${this.API_PATH}/sendmail`,
        { name, email, text },
        { timeout: 2000 }
      );
      return response.data;
    } catch (error: any) {
      // handle error as needed
      throw new Error(error.response?.data?.message || error.message);
    }
  }

  public getAuthenticatedUser = async (): Promise<User_WithoutId | null> => {
    try { 

      const email = this.axiosService.getEmail();

      if(!email)
        throw new Error("Can't get email address...");
      
      const response: AxiosResponse<User_WithoutId,any> = await this.axiosService.use.get<User_WithoutId>(`${this.API_PATH}/users/linkToAuth/${email}`);

      return response.data;

    }  
    catch (error: any) {
      
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null;
      }    
      else if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }
  }

  public getAllUsers = async (): Promise<User_WithoutId[]> => {
    const response = await this.axiosService.use.get<User_WithoutId[]>(`${this.API_PATH}/users`);
    return response.data;
  };

  public getApiInfo= async (): Promise<{message:string}> => {
    const response = await this.axiosService.use.get<{message:string}>(`${this.API_PATH}/info`);
    return response.data;
  };

  // public addAuthenticatedUser = async (userToCreate_withoutEmail: UserCreationAttributes_withoutemail): Promise<User_WithoutId> => {

  //   try {      
      
  //     const email = this.axiosService.getEmail();

  //     if(!email)
  //       throw new Error("Can't get email address...");

  //     const userToCreate: UserCreationAttributes = { email, ...userToCreate_withoutEmail };
  
  //     const response = await this.axiosService.use.post<User_WithoutId, AxiosResponse<User_WithoutId>, UserCreationAttributes>(`${this.API_PATH}/users`, userToCreate);
  //     return response.data;

  //   } 
  //   catch (error: any) {
      
  //     if (error instanceof Error) {
  //       throw new Error(error.message);
  //     } else {
  //       throw new Error("Unknown error");
  //     }
  //   }
  // };

  // public forceAuthenticatedUser = async (userToCreate: UserForcedCreationAttributes): Promise<User_WithoutId> => {

  //   try {  
  //     const response = await this.axiosService.use.post<User_WithoutId, AxiosResponse<User_WithoutId>, UserForcedCreationAttributes>(`${this.API_PATH}/users/force`, userToCreate);
  //     return response.data;

  //   } 
  //   catch (error: any) {
      
  //     if (error instanceof Error) {
  //       throw new Error(error.message);
  //     } else {
  //       throw new Error("Unknown error");
  //     }
  //   }
  // };

  public updateUserAgreementStatus = async (): Promise<User_WithoutId | null> => {
    try {
      const response = await this.axiosService.use.post<{ message: string, user: User_WithoutId }>(`${this.API_PATH}/users/authenticated/agreeTerms`);
      return response.data.user;

    } 
    catch (error: any) {
      
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null;
      }    
      else if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }
  }

  public claimBalance = async (): Promise<User_WithoutId | null> => {
    try {
      const response = await this.axiosService.use.post<{ message: string, user: User_WithoutId }>(`${this.API_PATH}/users/authenticated/claimBalance`);
      return response.data.user;
    } 
    catch (error: any) {
      
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null;
      }    
      else if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }
  }

  public getOfferById = async (offerId: number): Promise<OfferWithScope_UserWithoutId | null> => {
    try {
      const response: AxiosResponse<OfferWithScope_UserWithoutId,any> = await this.axiosService.use.get<OfferWithScope_UserWithoutId>(`${this.API_PATH}/offers/${offerId}`);
      return response.data;

    } 
    catch (error: any) {
      
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null;
      }    
      else if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }
  }

  public getOffersForAuthenticatedUser = async (): Promise<OfferWithScope_UserWithoutId | null> => {

    try {
      const response = await this.axiosService.use.get<OfferWithScope_UserWithoutId | null>(`${this.API_PATH}/offers/user/authenticated`);
      return response.data;

    } 
    catch (error: any) {
      
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null;
      }    
      else if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }    
  };

  public getOffersByProfession = async (profession: string): Promise<OfferWithScope_UserWithoutId[]> => {
    const response = await this.axiosService.use.get<OfferWithScope_UserWithoutId[]>(`${this.API_PATH}/offers/profession/${profession}`);
    return response.data;
  };

  public createOffer = async (offerToCreate: OfferCreationAttributes_FromUser): Promise<OfferWithScope_UserWithoutId> => {
    try {
      
      const response = await this.axiosService.use.post<OfferWithScope_UserWithoutId, AxiosResponse<OfferWithScope_UserWithoutId>, OfferCreationAttributes_FromUser>(`${this.API_PATH}/offers`, offerToCreate);
      
      return response.data;
    } 
    catch (error: any) {
      
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }
  };

  public withdrawOfferForLoggedInUser = async (): Promise<null> => {
    try {
      
      const response = await this.axiosService.use.delete(`${this.API_PATH}/offers/invalidate/authenticated`);
      
      return response.data;

    }     
    catch (error: any) {
      
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }

  };

  public createReferral = async (referralToCreate: ReferralCreationAttributes_FromUser): Promise<void> => {
    try {

      await this.axiosService.use.post<ReferralWithScope_UserWithoutId, AxiosResponse<ReferralWithScope_UserWithoutId>, ReferralCreationAttributes_FromUser>(`${this.API_PATH}/referrals`, referralToCreate);
    
    }     
    catch (error: any) {
      
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }
  };


  public getUserReferralsOut = async (): Promise<ReferralWithScope_UserWithoutId[]> => {
    try {

      const response = await this.axiosService.use.get<ReferralWithScope_UserWithoutId[]>(`${this.API_PATH}/referrals/referrer/authenticated`);
    
      return response.data;

    }     
    catch (error: any) {
      
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }

  };

  public getUserReferralsIn = async (): Promise<ReferralWithScope_UserWithoutId[]> => {
    try {

      const response = await this.axiosService.use.get<ReferralWithScope_UserWithoutId[]>(`${this.API_PATH}/referrals/offerer/authenticated`);
    
      return response.data;

    }     
    catch (error: any) {
      
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }
  };

  public setUserReferralsInStatus = async (referralId: number, newStatus: string): Promise<ReferralWithScope_UserWithoutId> => {
    try {

      const response = await this.axiosService.use.put<ReferralWithScope_UserWithoutId>(`${this.API_PATH}/referrals/${referralId}/status/${newStatus}`);
    
      return response.data;

    }     
    catch (error: any) {
      
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Unknown error");
      }
    }
  };

  // public setUserReferralsPayoutRequested= async (): Promise<ReferralWithScope_UserWithoutId[]> => {
  //   try {

  //     const response = await this.axiosService.use.put<ReferralWithScope_UserWithoutId[]>(`${this.API_PATH}/referrals/referrer/authenticated/payoutRequested`);
      
  //     return response.data;

  //   }     
  //   catch (error: any) {
      
  //     if (error instanceof Error) {
  //       throw new Error(error.message);
  //     } else {
  //       throw new Error("Unknown error");
  //     }
  //   }
  // };

}


export const APIAuthenticatedServiceContext = createContext<APIAuthenticatedService| null>(null);

export function useAPIAuthenticatedService() {      

    const context = useContext(APIAuthenticatedServiceContext);

    if (context === null) {
      throw new Error('useAPIAuthenticatedService must be used within a APIAuthenticatedServiceContext.Provider');
    }
    return context;
  }