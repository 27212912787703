import React, {useState} from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, DialogActions, Dialog, DialogTitle, IconButton, DialogContent, Container, Card, CardHeader, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography,  useMediaQuery, useTheme } from '@mui/material';
import { useAPIAuthenticatedService } from '../../helpers/APICallsAuthenticated';
import { ReferralWithScope_UserWithoutId, CacheKeys } from '../../helpers/DataStructures';
import DateTimeFormat from '../../helpers/DateTimeFormat';

import CloseIcon from '@mui/icons-material/Close';

import { translateFromStatusToShortTextForOfferer } from '../../helpers/Language';

import { InHouseCardHeader, InHouseCardContent } from '../../components/CustomMUI';

import { toast } from 'react-toastify';

import DisplayReferralIn from '../../components/DisplayReferralIn';

const ReferralsAppReferralsIn: React.FC = () => {

  const apiServiceInstance = useAPIAuthenticatedService();  
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState<ReferralWithScope_UserWithoutId | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = (referral: ReferralWithScope_UserWithoutId) => {
    setSelectedReferral(referral);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const { isLoading, isError, data, error } = useQuery(
    [CacheKeys.REFERRALS_TO_USER],
    () => apiServiceInstance.getUserReferralsIn(),
    { keepPreviousData: true }
  );

   const setUserReferralsInStatus = async ({ referralId, newStatus }: {referralId: number, newStatus: string}) => {
    return apiServiceInstance.setUserReferralsInStatus(referralId, newStatus);
  };

  const updateStatusMutation = useMutation(setUserReferralsInStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([CacheKeys.REFERRALS_TO_USER]);
      toast.success("Referral Updated");
      handleClose(); // Close the dialog
    },
    onError: (error: any) => {
      toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
    },
  });

  const handleUpdateStatus = (newStatus: string) => {

    if(selectedReferral)
      updateStatusMutation.mutate({ referralId: selectedReferral.id, newStatus });
  };

  return (

    <>
    
      <Card variant="outlined" title='dd' style={{ margin: '0 auto' }}>
       
              <InHouseCardHeader title='Referrals Received'  />  
              <InHouseCardContent style={{ textAlign: 'center' }} > 

              { 
                isLoading || data === undefined  ? 
                (
                    <>ff</>
                ) : data && data?.length === 0 ?
                (
                    <Typography variant='body2'>No referrals received yet</Typography>
                ) :
                (
                    <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            {isMobile && 
                              <TableRow>
                                <TableCell sx={{ width: '25%' }}>From</TableCell>
                                <TableCell sx={{ width: '25%' }}>Received</TableCell>
                                <TableCell sx={{ width: '25%' }}>Customer</TableCell> 
                                <TableCell sx={{ width: '25%' }}>Status</TableCell>
                                </TableRow>
                            }
                            {!isMobile && 
                              <TableRow>
                                <TableCell sx={{ width: '20%' }}>From</TableCell>
                                <TableCell sx={{ width: '20%' }}>Received</TableCell>
                                <TableCell sx={{ width: '20%' }}>Customer</TableCell> 
                                <TableCell sx={{ width: '20%' }}>Status</TableCell>
                                <TableCell sx={{ width: '20%' }}></TableCell>
                              </TableRow>                       
                            }                          
                        </TableHead>
                        <TableBody>
                          {data.map((referral: ReferralWithScope_UserWithoutId) => (
                            <React.Fragment key={referral.id}>
                              <TableRow
                                onClick={() => handleOpen(referral)}
                                style={{ cursor: 'pointer' }}
                              >
                                <TableCell>
                                  {referral.user.givenName} {referral.user.familyName}
                                </TableCell>
                                <TableCell>{DateTimeFormat(referral.created)}</TableCell>
                                <TableCell>{referral.theReferralName}</TableCell>
                                {/* <TableCell>{referral.theReferralContact}</TableCell> */}
                                <TableCell>
                                  {translateFromStatusToShortTextForOfferer(referral.status)}
                                </TableCell>
                                {!isMobile && (
                                  <TableCell>
                                    <Button>View</Button>
                                  </TableCell>
                                )}
                              </TableRow>
                              {/* <TableRow
                                onClick={() => handleOpen(referral)}
                                style={{ cursor: 'pointer' }}
                              >
                                <TableCell colSpan={isMobile ? 5 : 6}>
                                  some stuff if that fills the whole row
                                </TableCell>
                              </TableRow> */}
                            </React.Fragment>
                          ))}
                        </TableBody>

                    </Table>
                    </TableContainer>
                )
            }

             
                  
              </InHouseCardContent>               
                          
      </Card>

      { selectedReferral && (
          <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DisplayReferralIn referral={selectedReferral} />
          </DialogContent>

          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2, // Adds spacing between buttons
            }}
          >

            { selectedReferral.status === 'created' && (
              //created', 'goingAhead', 'succeeded', 'failed', 'alreadyaCustomer', 'payoutCollected', 'payoutSent'
              <>
                <Button variant='contained'  onClick={() => handleUpdateStatus('goingAhead')} color="primary">
                    Success - I'm going ahead with this job
                </Button>
                <Button variant='contained' onClick={() => handleUpdateStatus('failed')} color="secondary">
                    I didn't win this job
                </Button>
                <Button variant='contained' onClick={() => handleUpdateStatus('alreadyaCustomer')} color="secondary">
                    This is already my customer
                </Button>
              </>
            )}
            { selectedReferral.status === 'goingAhead' && (
              <>
                <Button variant='contained' onClick={() => handleUpdateStatus('succeeded')} color="primary">
                    Success - Job is complete
                </Button>
                <Button variant='contained' onClick={() => handleUpdateStatus('failed')} color="secondary">
                    Job didn't go ahead
                </Button>
              </>
            )}

          </DialogActions>

          {/* { selectedReferral.status === 'succeeded' && ( <text></text> )} */}
          {/* { selectedReferral.status === 'failed' && ( <text></text> )} */}
          {/* { selectedReferral.status === 'alreadyaCustomer' && ( <text></text> )} */}
          {/* { selectedReferral.status === 'payoutCollected' && ( <text></text> )} */}
          {/* { selectedReferral.status === 'payoutSent' && ( <text></text> )} */}

        </Dialog>
        )}

        </>
    
  );
};

export default ReferralsAppReferralsIn;
