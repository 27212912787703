// src/App.tsx

import { createBrowserRouter, RouterProvider, Route, Routes, Outlet, ScrollRestoration, useLocation, useParams  } from 'react-router-dom';

import React, { useEffect } from 'react';
import Home from './pages/Home';
import TextPage from './pages/TextPage';
import Contact from './pages/Contact';
//import ContactAboutServiceProvider from './pages/ContactAboutServiceProvider';

//import Navbar from './components/NavBar';

import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

import Fade from '@mui/material/Fade';
import { Box, CssBaseline, GlobalStyles, Typography  } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

import Footer from './components/Footer';
// import { ToastContainer } from 'react-toastify';

import { green, purple, orange, red, pink, blue } from '@mui/material/colors';

import CookieConsent from './components/CookieConsent';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from './components/NavBar';

import ReferralsAppParent from './referralsApp/ReferralsAppParent';

import {APIUnauthenticatedService, APIUnauthenticatedServiceContext} from './helpers/APICallsUnauthenticated';
import { ConfigInfoService, ConfigInfoServiceContext  } from './helpers/ConfigInfo';
import { getConfig } from '@testing-library/react';

import Login from './components/Login';
import ReferralsAppAuthenticated from './referralsApp/referralsAppAuthenticated/ReferralsAppAuthenticated';
import SignUp from './pages/SignUp';

import GetSignedUp from './pages/GetSignedUp';

import { loadGoogleAnalytics } from './helpers/analyticsUtils';
import AboutUsPage from './pages/AboutUs';

const App: React.FC = () => {  

 



  const configInfoService = ConfigInfoService.getInstance();
  const apiUnauthenticatedService = APIUnauthenticatedService.getInstance(configInfoService);


  const ourGreen = '#08f228';
  const ourGreenDark = '#23b537';
  const ourGreen2 = '#09f128';

  const lightGrey = '#333';

  let theme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            border: '1px solid green',
          },
        },
      },
      // MuiOutlinedInput: {
      //   styleOverrides: {
      //     root: {
      //       '& fieldset': {
      //         borderColor: 'white',
      //       },
      //       '&:hover fieldset': {
      //         borderColor: 'white',
      //       },
      //       '&.Mui-focused fieldset': {
      //         borderColor: 'white',
      //       },
      //     },
      //     input: {
      //       color: '#000', 
      //       backgroundColor: '#fff',
      //     },
      //   },
      // },    
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            backgroundColor: lightGrey
          }
        }
      },
      // MuiAppBar: {
      //   styleOverrides: {
      //     root: {
      //       boxShadow: 'none',
      //       elevation: 0, 
      //       borderBottom: `3px solid ${orange[800]}` 
      //     }
      //   }
      // } 
    },
    palette: {
      mode: 'dark',
      primary: {
        main: ourGreen,
        dark: ourGreenDark,
        light: green[200],
      },
      secondary: {
        main: '#f00',
        dark: '#000',
        light: '#fff',
      },      
      info: {
        main: blue[500],
        dark: blue[700],
        light: blue[300],
      },
      text: {
        primary: 'rgba(255, 255, 255, 0.87)', // Now this is necessary to have the desired opacity
      },      
    },
    typography: {
      fontFamily: 'Monda, sans-serif',
      h1: {fontFamily: 'Monda, sans-serif'},
      h2: {fontFamily: 'Monda, sans-serif'},
      h3: {fontFamily: 'Monda, sans-serif'},
      h4: {fontFamily: 'Monda, sans-serif'},
      h5: {fontFamily: 'Monda, sans-serif'},
      h6: {fontFamily: 'Monda, sans-serif'},
      body1: {
        fontFamily: 'Arial, sans-serif',
        fontWeight: 300,
        '@media (min-width:600px)': {
          fontSize: '20px',
          lineHeight: '32px',
        },
        '@media (max-width:599px)': {
          fontSize: '18px',
          lineHeight: '29px',
        },
      },
      body2: {
        fontFamily: 'Arial, sans-serif',
        fontWeight: 'normal',
        '@media (min-width:600px)': {
          fontSize: '13px'
        },
        '@media (max-width:599px)': {
          fontSize: '11px',
        },
      },
      bigPrimary_body2: {
        fontFamily: 'Arial, sans-serif',
        fontWeight: 'bold',
        '@media (min-width:600px)': {
          fontSize: '15px'
        },
        '@media (max-width:599px)': {
          fontSize: '13px',
        },
        color: ourGreen,
      },
      bigPrimary: {
        '@media (min-width:600px)': {
          fontSize: '28px'
        },
        '@media (max-width:599px)': {
          fontSize: '18px'
        },
        color: ourGreen,
        fontWeight: 700,
        fontFamily: 'Monda, sans-serif',
      }, 
      hugePrimary: {
        fontSize: '70px',
        color: ourGreen,
        fontWeight: 700,
        fontFamily: 'Monda, sans-serif',
      },
      cardSubtitle: {
        '@media (min-width:600px)': {
          fontSize: '28px'
        },
        '@media (max-width:599px)': {
          fontSize: '18px',
        },
        fontWeight: 700,
        fontFamily: 'Arial, sans-serif',
      }, 
    }, 
  }); 

  useEffect(() => {
    // List the hostname(s) you consider production
    const productionDomains = ['inhouseapp.co.uk', 'www.inhouseapp.co.uk'];

    if (productionDomains.includes(window.location.hostname)) {
      // Your Google Analytics measurement ID
      const GA_TRACKING_ID = 'G-8KNW3BX236';
      loadGoogleAnalytics(GA_TRACKING_ID);
    }
  }, []);
  

  theme = responsiveFontSizes(theme);
  
  const router = createBrowserRouter([    
    {
      Component: Layout,
      children: [
        { path: "/", Component: Home },
        { path: "/dashboard", Component: () => <ReferralsAppParent showIfLoggedIn={<ReferralsAppAuthenticated/>} showIfLoggedOut={<Login/>} /> },
        { path: "/terms-and-conditions", Component: () => <TextPage filename={'text/terms-and-conditions.txt'} /> },
        { path: "/about-us", Component: () => <AboutUsPage  /> },
        { path: "/contact", Component: Contact},        
        //{ path: "/ContactAboutServiceProvider", Component: ContactAboutServiceProvider},
        { path: "/signup", Component: SignUp},
        { path: "/getsignedup", Component: GetSignedUp},
        { path: "*", Component: Root }
      ],
    }
  ]);
  
  function Root() {
    return (
      <Routes>
          {/* <Route path="/info/about" element={<About />} />
          <Route path="/info/termsofuse"  element={<TermsOfUse/>} />
          <Route path="/info/privacy"  element={<PrivacyPolicy/>} />
          <Route path="/info/procedureGuide"  element={<ProcedureGuide/>} />
          <Route path="/info/contactus"  element={<ContactUs/>} />          
          <Route path="/searchresult" element={<SearchResultPage />} />   
          <Route path="/search/" element={<SearchPage />} /> 
          <Route path="/search/showdetails/:urltreatment/:urlhospitalname" element={<SearchResultPageNew />} /> 
          <Route path="*" element={<NotFoundPage />} /> */}
      </Routes> 
    );
  }
  
  function Layout() {

    const { pathname } = useLocation();

    return (
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ScrollRestoration />
          <GlobalStyles styles={{ 
            body: { 
              backgroundColor: '#000'
            } 
          }}/>
          <ConfigInfoServiceContext.Provider value={configInfoService}>
          <APIUnauthenticatedServiceContext.Provider value={apiUnauthenticatedService}>

          <Fade in={true} timeout={1000}>                  
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              minHeight: '100vh',
              width: '100%',
              position: 'relative', // Necessary for proper layering
            }}
          >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: ['100%', null, '100%'],
                  height: '100%',
                  backgroundImage: 'url(background.png)',
                  backgroundSize: ['cover', null, '50%'], // 'cover' for small, null keeps 'cover' for medium, '1000px' for large screens
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: ['center center', null, 'right bottom'],
                  backgroundAttachment: 'fixed',
                  '@media (max-width: 600px)': {
                    filter: 'brightness(50%)', // Darken the image only on small screens
                  },
                  zIndex: -1,
                }}
              ></Box>



                <Box sx={{ minHeight: '100vh', width: '100%', maxWidth: '1200px', display: 'flex', flexDirection: "column"}}>
                
                    <NavBar />
                    <Box sx={{ px: 0.5, py: {xs: 0.5, sm: 2, md: 4},   flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                      <Outlet />
                    </Box>
                    <Footer/>                  

                </Box> 
                  
              <CookieConsent/>

            </Box>
          </Fade> 
        
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            
          />
      </APIUnauthenticatedServiceContext.Provider>
      </ConfigInfoServiceContext.Provider>    
      </ThemeProvider>
      </>
    );
  }
  
  return (<RouterProvider router={router} /> ); 
  
}

export default App;