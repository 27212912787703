import React from "react";
import { Grid, Box, Card, CardContent, Typography, Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

// Define sections with title, icon, and text.
const sections = [
  {
    title: "Welcome",
    icon: <HomeIcon fontSize="large" color="primary" />,
    text: "As a professional in the UK property market, you understand the importance of connecting clients with trusted partners. In House streamlines that process by uniting all types of property-related services under one easy to use platform, making it easier than ever to refer and collaborate. We’re here to help you build valuable relationships, simplify transactions, and enhance the overall client experience.",
  },
  {
    title: "Empowering Key Market Players",
    icon: <PeopleIcon fontSize="large" color="primary" />,
    text: "As one of the four key players in the UK property market—whether you're an Estate Agent, Mortgage Advisor, Conveyancer, or Surveyor—you know the importance of recommending services from trusted professionals. In House is designed to streamline this process and make collaboration more rewarding than ever.",
  },
  {
    title: "Effortless Referrals",
    icon: <PhoneAndroidIcon fontSize="large" color="primary" />,
    text: "Our user-friendly app and website allow you to easily send your client's name and contact details to the relevant service provider. When the referral is successful, you'll earn an introductory fee. It’s a simple, effective way to help your clients while adding a new revenue stream to your business.",
  },
  {
    title: "Redefining Professional Recommendations",
    icon: <EmojiObjectsIcon fontSize="large" color="primary" />,
    text: "We believe in the power of professional recommendations. With the trust your clients already have in you, say goodbye to random marketing and wasted advertising budgets. With In House, every referral is a guaranteed success—a true 100% win.",
  },
  {
    title: "Join the Revolution",
    icon: <RocketLaunchIcon fontSize="large" color="primary" />,
    text: "Transform the way you collaborate with other property professionals and accelerate your business growth. Join In House today to experience the benefits of a dedicated in-house team of property specialists, optimized revenue streams, and a marketing strategy that truly delivers.",
  },
];

const AboutUsPage: React.FC = () => {
  return (
    <Card sx={{ margin: { xs: "10px", md: "20px" } }}>
      <CardContent sx={{ padding: { xs: "20px", md: "100px" } }}>
        <Box sx={{ maxWidth: "726px", margin: "0 auto" }}>
          {sections.map((section, index) => (
            <Box key={index} sx={{ marginBottom: "40px" }}>
              <Grid container spacing={2} alignItems="flex-start">
                {/* Icon column with responsive margin */}
                <Grid item sx={{ marginRight: { xs: 0, md: "20px" } }}>
                  {section.icon}
                </Grid>
                {/* Text column */}
                <Grid item xs>
                  <Typography variant="h5" gutterBottom>
                    {section.title}
                  </Typography>
                  {/* Added marginTop to separate title and paragraph */}
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", marginTop: "20px" }}
                  >
                    {section.text}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
         
        </Box>
      </CardContent>
    </Card>
  );
};

export default AboutUsPage;
