// src/components/MyOffersDataTable.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Container, Tab, Tabs } from '@mui/material';

import { motion, AnimatePresence } from "framer-motion";

import { useQuery } from '@tanstack/react-query';

import { useAPIAuthenticatedService  } from '../../helpers/APICallsAuthenticated';
import {  User_WithoutId, CacheKeys } from '../../helpers/DataStructures';

//import CreateUserForm from '../../forms/CreateUserForm';

import { assign, Machine, MachineOptions, GuardPredicate, MachineConfig } from 'xstate';
import { useMachine } from '@xstate/react';

import TandCForm from '../../forms/TandCForm';
import ReferralsAppHello from './ReferralsAppHello';
import ReferralsAppMakeReferral from './ReferralsAppMakeReferral';
import Busy from '../../components/Busy';

import ScreenSelected from './ScreenSelected';
import ReferralsAppMakeRecommendation from './ReferralsAppMakeRecommendation';
//import ForceUserForm from '../../forms/ForceUserForm';

export enum usersScreenSelection {
  "hello",
  "makeReferral",
  "makeRecommendation",
  "forceUser"
}

const ReferralsAppAuthenticated: React.FC = () => {

  const [stateMachine, sendToStateMachine] = useMachine( authMachine );

  const [screenSelection, setScreenSelection] = useState<usersScreenSelection>(usersScreenSelection.hello);

  const apiServiceInstance = useAPIAuthenticatedService();


  const { isLoading, isError, data: data_loggedInUser, error } = useQuery(
    [CacheKeys.LOGGED_IN_USER], 
    async () => {
      
        const user = await apiServiceInstance.getAuthenticatedUser();
        return user;      
    },
    {
      onSuccess: (data) => {        
        sendToStateMachine({ type: AuthEventTypes.LOGGED_IN_USER_DETAILS_CHANGE, data: {user: data, screen: screenSelection}, isError: false });
      },
      onError: (error) => {
        sendToStateMachine({ type: AuthEventTypes.LOGGED_IN_USER_DETAILS_CHANGE, data: {user: null, screen: screenSelection}, isError: true });
      }      
    }
  );  

  useEffect(() => {
    sendToStateMachine({ type: AuthEventTypes.SCREEN_SELECTED, data: {user: data_loggedInUser, screen: screenSelection}, isError: false });
  }, [screenSelection]);  

  if ( isError ) {
    throw new Error("Can't load data");;
  }

  if (stateMachine.matches(AuthStateNames.ERROR)  )
  { 
    throw new Error("Can't load data ");
  }

  return (
    <> 
          <AnimatePresence mode='wait'>
              <motion.div key={stateMachine.value.toString()} style={{ width: '100%' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
                
              <Container maxWidth="sm" style={{ margin: '0 auto', padding: '0' }} >
                {stateMachine.matches(AuthStateNames.UNVERIFIED) && <Busy message='Loading....'/>}
                {stateMachine.matches(AuthStateNames.NOT_STORED) && <p>create user form</p>}
                {stateMachine.matches(AuthStateNames.STORED_WITHOUT_CONSENT) && <TandCForm />}
                {stateMachine.matches(AuthStateNames.HELLO) && 
                  <ReferralsAppHello screenSelection={screenSelection} setScreenSelection={setScreenSelection}/>
                }
                {stateMachine.matches(AuthStateNames.MAKE_REFERRAL) && 
                  <ScreenSelected titleText='Make a Referral' screenSelection={screenSelection} setScreenSelection={setScreenSelection}>
                    <ReferralsAppMakeReferral />
                  </ScreenSelected>
                }
                {stateMachine.matches(AuthStateNames.MAKE_RECOMMENDATION) && 
                  <ScreenSelected titleText='Make a Recommendation' screenSelection={screenSelection} setScreenSelection={setScreenSelection}>
                   <ReferralsAppMakeRecommendation />
                  </ScreenSelected>
                }
                {stateMachine.matches(AuthStateNames.FORCE_USER) && 
                  <ScreenSelected titleText='Add a user' screenSelection={screenSelection} setScreenSelection={setScreenSelection}>
                    <p>force user form</p>
                  </ScreenSelected>
                }
              </Container>
                  
              </motion.div>          
          </AnimatePresence>
    
    </>    
  );
};

export default ReferralsAppAuthenticated;

type AuthContext = {
  
};

const initialAuthContext: AuthContext = {
  
};

enum AuthStateNames {
  UNVERIFIED = 'unverified',
  ERROR = 'error',
  NOT_STORED = 'notStored',
  STORED_WITHOUT_CONSENT = 'storedWithoutConsent',
  HELLO = 'hello',
  MAKE_REFERRAL = 'makeReferral',
  MAKE_RECOMMENDATION = 'makeRecommendation',  
  FORCE_USER = 'forceUser',
}

interface AuthSchema {
  states: {
    [AuthStateNames.UNVERIFIED]: {};
    [AuthStateNames.ERROR]: {};
    [AuthStateNames.NOT_STORED]: {};
    [AuthStateNames.STORED_WITHOUT_CONSENT]: {};
    [AuthStateNames.HELLO]: {};
    [AuthStateNames.MAKE_REFERRAL]: {};
    [AuthStateNames.MAKE_RECOMMENDATION]: {};
    [AuthStateNames.FORCE_USER]: {};
  };
}

enum AuthEventTypes {
  LOGGED_IN_USER_DETAILS_CHANGE = 'LOGGED_IN_USER_DETAILS_CHANGE',
  SCREEN_SELECTED = 'SCREEN_SELECTED'
}

interface AuthEvent {
  type: AuthEventTypes;
  data: { user: User_WithoutId | null | undefined, screen: usersScreenSelection};
  isError: Boolean;
}


const isError: GuardPredicate<AuthContext, AuthEvent> = {
  type: 'xstate.guard',
  name: 'isError',
  predicate: (context, event) => Boolean(event.isError),
};

const isNotStored: GuardPredicate<AuthContext, AuthEvent> = {
  type: 'xstate.guard',
  name: 'isNotStored',
  predicate: (context, event) => 
    event.data.user !== undefined 
    && event.data.user === null
};

const isStoredWithoutConsent: GuardPredicate<AuthContext, AuthEvent> = {
  type: 'xstate.guard',
  name: 'isStoredWithoutConsent',
  predicate: (context, event) => 
    event.data.user !== undefined 
    && event.data.user !== null 
    && Boolean(!event.data.user.hasAgreedTsAndCs),
};

const isStoredWithConsent: GuardPredicate<AuthContext, AuthEvent> = {
  type: 'xstate.guard',
  name: 'isStoredWithConsent',
  predicate: (context, event) => 
    event.data.user !== undefined && 
    event.data.user !== null 
    && Boolean(event.data.user.hasAgreedTsAndCs)
};

const isHelloScreen: GuardPredicate<AuthContext, AuthEvent> = {
  type: 'xstate.guard',
  name: 'isHelloScreen',
  predicate: (context, event) => event.data.screen === usersScreenSelection.hello,
};

const isMakeReferral: GuardPredicate<AuthContext, AuthEvent> = {
  type: 'xstate.guard',
  name: 'isMakeReferral',
  predicate: (context, event) => event.data.screen === usersScreenSelection.makeReferral,
};

const isForceUser: GuardPredicate<AuthContext, AuthEvent> = {
  type: 'xstate.guard',
  name: 'isForceUser',
  predicate: (context, event) => event.data.screen === usersScreenSelection.forceUser,
};

const authOptions: Partial<MachineOptions<AuthContext, any>> = {
  actions: {
    setUser: assign({ user: (context, event) => event.data } )
  },
};


const authConfig: MachineConfig<AuthContext, AuthSchema, AuthEvent> = {
  id: 'authentication',
  initial: AuthStateNames.UNVERIFIED,
  context: initialAuthContext,
  states: {
    unverified: {
      on: {
        LOGGED_IN_USER_DETAILS_CHANGE: [
          { target: AuthStateNames.ERROR, cond: isError },
          { target: AuthStateNames.NOT_STORED, cond: isNotStored },
          { target: AuthStateNames.STORED_WITHOUT_CONSENT, cond: isStoredWithoutConsent },
          { target: AuthStateNames.HELLO, cond: isStoredWithConsent }
        ]
      }
    },   
    error: {},
    notStored: {
      on: {
        LOGGED_IN_USER_DETAILS_CHANGE: [
          { target: AuthStateNames.ERROR, cond: isError },
          { target: AuthStateNames.NOT_STORED, cond: isNotStored },
          { target: AuthStateNames.STORED_WITHOUT_CONSENT, cond: isStoredWithoutConsent },
          { target: AuthStateNames.HELLO, cond: isStoredWithConsent }
        ]     
      }
    },
    storedWithoutConsent: {
      on: {
        LOGGED_IN_USER_DETAILS_CHANGE: [
          { target: AuthStateNames.ERROR, cond: isError },
          { target: AuthStateNames.NOT_STORED, cond: isNotStored },
          { target: AuthStateNames.STORED_WITHOUT_CONSENT, cond: isStoredWithoutConsent },
          { target: AuthStateNames.HELLO, cond: isStoredWithConsent }
        ]
      }
    },    
    hello: {
      on: {
        SCREEN_SELECTED: [
          { target: AuthStateNames.ERROR, cond: isError },
          { target: AuthStateNames.NOT_STORED, cond: isNotStored },
          { target: AuthStateNames.STORED_WITHOUT_CONSENT, cond: isStoredWithoutConsent },
          { target: AuthStateNames.MAKE_REFERRAL, cond: isMakeReferral },
          { target: AuthStateNames.MAKE_RECOMMENDATION, cond: (context, event) => event.data.screen === usersScreenSelection.makeRecommendation },
          { target: AuthStateNames.FORCE_USER, cond: isForceUser }
        ]
      }
    },
    makeReferral: {
      on: {
        SCREEN_SELECTED: [
          { target: AuthStateNames.ERROR, cond: isError },
          { target: AuthStateNames.NOT_STORED, cond: isNotStored },
          { target: AuthStateNames.STORED_WITHOUT_CONSENT, cond: isStoredWithoutConsent },
          { target: AuthStateNames.HELLO, cond: isHelloScreen }
        ]
      }
    },    
    makeRecommendation: {
      on: {
        SCREEN_SELECTED: [
          { target: AuthStateNames.ERROR, cond: isError },
          { target: AuthStateNames.NOT_STORED, cond: isNotStored },
          { target: AuthStateNames.STORED_WITHOUT_CONSENT, cond: isStoredWithoutConsent },       
          { target: AuthStateNames.HELLO, cond: isHelloScreen }
        ]
      }
    },
    forceUser: {
      on: {
        SCREEN_SELECTED: [
          { target: AuthStateNames.ERROR, cond: isError },
          { target: AuthStateNames.NOT_STORED, cond: isNotStored },
          { target: AuthStateNames.STORED_WITHOUT_CONSENT, cond: isStoredWithoutConsent },
          { target: AuthStateNames.HELLO, cond: isHelloScreen }
        ]
      }
    },
  }
};


const authMachine = Machine<AuthContext, AuthSchema, AuthEvent>(authConfig, authOptions);



