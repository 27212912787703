import React, { useState } from "react";

import { useAuth0 } from '@auth0/auth0-react';

import { Grid, Box, Card, Container, CardContent, Typography, Modal, Accordion, AccordionSummary, AccordionDetails, } from '@mui/material';

import { useNavigate } from "react-router-dom";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import LoadingButton from '@mui/lab/LoadingButton';
import { BootstrapInput } from "../components/CustomMUI";

//import { SensibleLoadingRequest } from '../helpers/SensibleLoadingRequests';
//import MuiAlert from '@mui/material/Alert';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'


import { toast } from 'react-toastify';

import { BoxProps , styled } from '@mui/system';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useMediaQuery from '@mui/material/useMediaQuery';



import ReferralsAppParent from "../referralsApp/ReferralsAppParent";
import ReferralsAppUnauthenticatedMakeReferral from "../referralsApp/referralsAppUnauthenticated/ReferralsAppUnauthenticatedMakeReferral";
import ReferralsAppMakeReferral from "../referralsApp/referralsAppAuthenticated/ReferralsAppMakeReferral";




// Define a darker AccordionDetails
const DarkerAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200], // change this to the color you want
}));

const HomePage: React.FC = () => {
  
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
 
  return (
    <>
    <Box sx={{margin: {sm: "5px", md: "20px" }}}>
      <Grid container spacing={4} direction={'row'} >

        <Grid item marginTop={'5%'} xs={12} sm={4} display='flex' justifyContent={{xs: 'center', sm: 'flex-start' }} alignItems={{xs: 'center', sm: 'flex-start' }}>

          <Box marginLeft={{xs: '0px', sm: '30px' }} width={{ xs: '200px', md: '300px' }} flexShrink={0} >
            <img src="logo_tag.png" style={{width:'100%'}} alt="Logo" />
          </Box>

        </Grid>
        <Grid item xs={12} sm={8}>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography align="left" variant="h4" sx={{ lineHeight: 1.5, fontWeight: 900, fontSize: { xs: '22px', sm: '28px', md: '44px' } }}>
              Get paid for referring work<br/>
              Get new customers<br/>
              Help existing clients
            </Typography>            
          </Box>

        </Grid>

        <Grid item xs={12} sx={{marginTop: '0px', padding: {sm: "0px", md: "0px" }}} >       
            <ReferralsAppParent showIfLoggedIn={<ReferralsAppMakeReferral/>} showIfLoggedOut={<ReferralsAppUnauthenticatedMakeReferral/>} />
       
        </Grid>

        <Grid item xs={12} sx={{marginTop: '20px'}}>
            <Container style={{margin: '0 auto', padding: '0', maxWidth:'800px' }} >

            <SignupForm />
            </Container>
        </Grid>

        {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px'}}>

            <ShareTheLink sx={{ order: isSmallScreen ? 2 : 1, flexGrow: 1, height: '200px', maxWidth: '400px', borderRadius: '20px', borderColor: theme.palette.primary.main, borderWidth: 1, borderStyle: 'solid'}} />
            <SignUpWithYourSmartPhone sx={{order: isSmallScreen ? 1: 2, flexGrow: 1, height: '200px', maxWidth: '400px', borderRadius: '20px', borderColor: theme.palette.primary.main, borderWidth: 1, borderStyle: 'solid'}} />
            <SuggestAServiceProvider sx={{order: isSmallScreen ? 3 : 3, lexGrow: 1, height: '200px', maxWidth: '400px', borderRadius: '20px', borderColor: theme.palette.primary.main, borderWidth: 1, borderStyle: 'solid'}}/>    

        </Grid> */}
        
        <Grid item > 
        <Typography variant='h3' sx={{marginBottom: '25px', fontWeight: 700}}>Frequently Asked Questions</Typography>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item >
            <MyAccordionComponent title="How is this another revenue stream?" alwaysBorder={true}>
              Instead of suggesting a couple of business contacts to your client when they require additional services, using In House pays a commission when the referral is successful.
            </MyAccordionComponent>
            <MyAccordionComponent title="How is In House 100% effective?">
              Using In House as a marketing strategy, you only pay commission when you receive a successful referral.
            </MyAccordionComponent>
            <MyAccordionComponent title="How does this create work?">
              Signing up to In House opens your business to a vast number of potential introducers. For example: If you are a surveyor covering a number of postcodes, you could be receiving referrals from literally hundreds of individual Estate Agents, and vice versa.
            </MyAccordionComponent>
            <MyAccordionComponent title="Is the process complicated?">
              No. Our user-friendly app and website allow you to easily send your client's name and contact details to the relevant service provider. The In House App does the rest whilst informing you of the progress every step of the way. We then automatically pay you your commission at the end.
            </MyAccordionComponent>
            <MyAccordionComponent title="What should I offer as my commission rate?">
              This depends on how much work you want, but, as a rule of thumb, the average marketing budget for this sector is 10% of turnover. So, if the fee for the service you are providing is in the region of £300 we would suggest £30 and so forth.
            </MyAccordionComponent>
            
            <MyAccordionComponent title="Can I add my own trusted contacts?">
                      <>
                      Yes. Please sign up and use the link on your dashboard to recommend other businesses you trust. We will then work to add them to the site.
                      </>
            </MyAccordionComponent>

            <MyAccordionComponent title="How do I join?">
                  <SignupForm />
            </MyAccordionComponent>

              </Grid>
            </Grid>

        </Grid>
      </Grid>
    </Box>
    </>
  );
}

const SignupForm: React.FC = () => {
  const { logout, loginWithRedirect, isAuthenticated  } = useAuth0();
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {



    e.preventDefault();
    loginWithRedirect({authorizationParams: {
      screen_hint: "signup",
      login_hint: email
    }});
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 2  }}>

        {/* <Grid item xs={12} sm={8} display={'flex'} justifyContent={{xs: 'center', sm: 'flex-end'}}>
    
              <BootstrapInput 
                value={email}
                onChange={e => setEmail(e.target.value)}
                sx={{width: { xs: '300px', sm: '500px', md: '600px'}}}
                placeholder="Enter your email..." 
              />         
        </Grid> */}

        {/* <Grid item xs={12} sm={4} display={'flex'} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
          
          <Button variant="contained" color="secondary" type="submit" sx={{width: {xs: '50%', sm: '100%'}}}>
            Get Started <NavigateNextIcon/>
          </Button>
          
        </Grid> */}

        <Grid item xs={12} display={'flex'} justifyContent={{xs: 'center'}}>
    
        <Button 
          variant="contained" 
          color="secondary" 
          sx={{
            width: {xs: '60%', sm: '50%'}, 
            fontSize: '1.25rem' // Adjust the font size as needed
          }}
          onClick={() => {
            if (isAuthenticated) {          
              logout({ logoutParams: {
                returnTo: window.location.origin + '/getsignedup',
              } });
            } else { 
              navigate('/getsignedup');
            }
          }}
        >
          Get Signed Up <NavigateNextIcon/>
        </Button>

        </Grid>

      </Grid>
    </form>
  );
}

// const ShareTheLink: React.FC<BoxProps > = ({ sx, ...other  }) => {  
//   const [loadingState, setLoadingShareLink] = useState(false);
//   const [messageToSend, setMessageToSend] = useState('');

//   const handleSendEmail = async () => {
//     setLoadingShareLink(true);

//     try {
//       const response = await MailToTeam(messageToSend,"","");

//       setLoadingShareLink(false);
//       toast.success('Email sent');
//     } catch (error) {
//       setLoadingShareLink(false);
//       toast.error('Error sending email');
//     }
//   };

//   return (
//     <Card sx={{ display: 'flex', ...sx }}>
//       <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
//         <Typography variant='bigPrimary'  >
//           Share the link
//         </Typography>

//         <BootstrapInput
//           value={messageToSend}
//           sx={{ width: '80%' }}
//           onChange={(e) => {
//             const value = e.target.value;
//             setMessageToSend(value);
//           }}
//         />

//         <LoadingButton
//           color="secondary"
//           variant="contained"
//           sx={{ border: '2px solid #000', borderRadius: '14px', minWidth: '100px' }}
//           onClick={handleSendEmail}
//           loading={loadingState}
//         >
//           <SvgIcon
//             viewBox="0 0 32 32"
//             sx={{
//               fill: loadingState ? 'transparent' : '#fff',
//               stroke: loadingState ? 'transparent' : '#fff',
//               strokeWidth: '1',
//               strokeMitrelimit: '10',
//               opacity: loadingState ? 0 : 1,
//               transition: 'opacity 0.3s ease-in-out',
//             }}
//           >
//             <polygon points="8.65,24.4 17.05,16 8.65,7.6 12.25,4 24.25,16 12.25,28 " />
//           </SvgIcon>
//         </LoadingButton>
//       </CardContent>
//     </Card>
//   );
// };

// const SuggestAServiceProvider: React.FC<BoxProps >= ({ sx, ...other  }) => {
//   const [loadingState, setLoadingShareLink] = useState(false);
//   const [messageToSend, setMessageToSend] = useState('');

//   const [modalOpen, setModalOpen] = useState(false); 
  
//   const handleModalClose = () => {
//     setModalOpen(false);
//   };

//   const handleSendEmail = async () => {
//     setLoadingShareLink(true);

//     try {
//       const response = await MailToTeam(messageToSend,"","");

//       setLoadingShareLink(false);
//       toast.success('Email sent');
//     } catch (error) {
//       setLoadingShareLink(false);
//       toast.error('Error sending email');
//     }
//   };

//   return (
//     <Card sx={{ display: 'flex', ...sx  }}>
//     <CardContent  sx={{ flexGrow: 1, display: 'flex',flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}> 

//     <Typography variant='bigPrimary'  align="center" >
//         Suggest your current trusted service provider
//       </Typography>
//       <Button color="secondary" variant="contained" sx={{border: '2px solid #000', borderRadius: '14px', minWidth: '100px' }} onClick={() => setModalOpen(true)}>
//         <SvgIcon viewBox="0 0 32 32" sx={{fill:'#fff', stroke: '#fff', strokeWidth: '1', strokeMitrelimit: '10' }}>
//           <polygon points="8.65,24.4 17.05,16 8.65,7.6 12.25,4 24.25,16 12.25,28 "/>
//         </SvgIcon>
//       </Button> 

//       <Modal
//         open={modalOpen}
//         onClose={handleModalClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <Box
//           sx={{
//             position: 'relative',
//             backgroundColor: 'background.paper',
//             borderRadius: '4px',
//             boxShadow: 24,
//             p: 4,
//             maxWidth: '600px',
//             display: 'flex',
//             flexDirection: 'column',
//             gap: '16px',
//             alignItems: 'center',
//           }}
//         >
//           <IconButton 
//             aria-label="close" 
//             onClick={handleModalClose} 
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//           <Typography variant='bigPrimary'  id="modal-modal-title">
//             Suggest your current trusted service provider
//           </Typography>
//           <Typography variant="body1" align="center">
//             Do you know any estate agents, surveyors, conveyancers, or mortgage brokers who would be interested in our service?
//           </Typography>
//           <BootstrapInput 
//             value={messageToSend || ''}
//             onChange={(e) => {
//                 const value = e.target.value;
//                 setMessageToSend(value);
//             }}
//             sx={{width: '60%'}}
//           />
//           <LoadingButton 
//             color="secondary" 
//             variant="contained" 
//             sx={{border: '2px solid #000', borderRadius: '14px', minWidth: '100px' }}
//             onClick={ () => handleSendEmail() }
//             loading={loadingState}
//           >
//             <SvgIcon viewBox="0 0 32 32" sx={{ 
//                         fill: loadingState ? 'transparent' : '#fff', 
//                         stroke: loadingState ? 'transparent' : '#fff', 
//                         strokeWidth: '1', 
//                         strokeMitrelimit: '10', 
//                         opacity: loadingState ? 0 : 1, 
//                         transition: 'opacity 0.3s ease-in-out' 
//                       }} >
//                       <polygon points="8.65,24.4 17.05,16 8.65,7.6 12.25,4 24.25,16 12.25,28 "/>
//             </SvgIcon>
//           </LoadingButton>
//         </Box>
//       </Modal>


//     </CardContent>
//   </Card>
//   );
// };

// const SignUpWithYourSmartPhone: React.FC<BoxProps > = ({ sx, ...other  }) => {  
 

// return (
//     <Card sx={{ display: 'flex', ...sx}}>
//           <CardContent sx={{ flexGrow: 1  }}>
            
//             <Grid container spacing={2} flexWrap={'nowrap'} flexDirection={'row'} alignItems="center" justifyContent="center">

//               <Grid item  >
//                 <Typography variant='bigPrimary'  sx={{lineHeight: 1}} >
//                   Sign up with your smart phone
//                 </Typography>
//               </Grid>

//               <Grid item  sx={{width: '100px'}}>               

//                   <Typography variant='bigPrimary'  sx={{ writingMode: 'vertical-lr', fontWeight: 900 }}  >
//                     SCAN ME
//                   </Typography>                   
                  
//               </Grid>

//               <Grid item  >               
                                     
//                   <img src="/QR.png" alt="QR Code" style={{ width: '150px'}}/>                    
                  
//               </Grid>

//             </Grid>

//           </CardContent>
//         </Card>
// );
// }


interface MyAccordionComponentProps {
  title: string;
  alwaysBorder?: boolean;
  children: React.ReactNode;
}

const MyAccordionComponent: React.FC<MyAccordionComponentProps> = ({ title, children, alwaysBorder = false }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleExpandChange = (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  return (
    <Accordion onChange={handleExpandChange}>
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} 
        sx={{
          borderColor: theme.palette.primary.main,
          borderWidth: 1,
          borderStyle: alwaysBorder ? 'solid' : expanded ? 'solid' : 'none solid solid solid',
        }}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default HomePage;

