// src/components/MyOffersDataTable.tsx
import React, { useState, useEffect } from 'react';
import { Link, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { motion } from 'framer-motion';

import { Box, Button, Container, Grid, Tab, Tabs, Card, CardHeader, CardContent, Typography} from '@mui/material';

import { useAPIAuthenticatedService } from '../../helpers/APICallsAuthenticated';

import { isDataEmpty, CacheKeys } from '../../helpers/DataStructures';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ReferralsAppOfferManagment from './ReferralsAppOfferManagement';

import { usersScreenSelection } from './ReferralsAppAuthenticated'
import RefferalsAppReferralsOut from './RefferalsAppReferralsOut';
import RefferalsAppReferralsIn from './ReferralsAppReferralsIn';

import { toast } from 'react-toastify';
import ReferralsAppAdmin from './ReferralsAppAdmin';
import { useAuth0 } from '@auth0/auth0-react';

import { translateFromTechnicalToEnglish } from '../../helpers/Language';;

const ReferralsAppHello: React.FC<{screenSelection: usersScreenSelection, setScreenSelection: (val: usersScreenSelection) => void}> = ({screenSelection, setScreenSelection}) => {
  
  const apiServiceInstance = useAPIAuthenticatedService();

  const queryClient = useQueryClient();

  const claimBalanceMutation = useMutation(apiServiceInstance.claimBalance, {
    onSuccess: (user) => {
      // Invalidate queries to refresh data (e.g., user data)
      queryClient.invalidateQueries([CacheKeys.LOGGED_IN_USER]);
      toast.success("Balance claimed successfully!");
      setIsClaimed(true);
    },
    onError: (error: any) => {
      toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
    },
  });

  const { user } = useAuth0();

  const [balanceWaitingCollection, setBalanceWaitingCollection] = useState(0);

  const [balanceReadyToCashOut, setBalanceReadyToCashOut] = useState(0);

  const [balancePaidOut, setBalancePaidOut] = useState(0);

  const [isClaimed, setIsClaimed] = useState(false);

  const { isLoading: isLoading_User, isError: isError_User, data: data_User, error: error_User } = useQuery(
    [CacheKeys.LOGGED_IN_USER], 
    apiServiceInstance.getAuthenticatedUser,    
    { keepPreviousData: true } 
  );
  
  const { isLoading: isLoading_UsersOffer, isError: isError_UsersOffer, data: data_UsersOffer, error: error_UsersOffer } = useQuery(
    [CacheKeys.LOGGED_IN_USERS_OFFER], 
    apiServiceInstance.getOffersForAuthenticatedUser,
    { keepPreviousData: true } 
  );
  
  const { isLoading: isLoading_Balance, isError: isError_Balance, data: data_Balance, error: error_Balance } = useQuery(
    [CacheKeys.REFERRALS_FROM_USER],
    apiServiceInstance.getUserReferralsOut,
    { 
      keepPreviousData: true,
      onSuccess: (data) => {
        const newBalanceWaitingCollection = data.reduce((total, referral) => {
          if (referral.status === 'succeeded' || referral.status === 'succeeded' ) {
            return total + referral.offer.offeringFixedCashAmount;
          }
          return total;
        }, 0);
        setBalanceWaitingCollection(newBalanceWaitingCollection);

        const newBalanceReadyToCashOut = data.reduce((total, referral) => {
          if (referral.status === 'payoutRequested' || referral.status === 'payoutRequested' ) {
            return total + referral.offer.offeringFixedCashAmount;
          }
          return total;
        }, 0);
        setBalanceReadyToCashOut(newBalanceReadyToCashOut,);

        const newBalancePaidOut = data.reduce((total, referral) => {
          if (referral.status === 'payoutSent' || referral.status === 'payoutSent' ) {
            return total + referral.offer.offeringFixedCashAmount;
          }
          return total;
        }, 0);
        setBalancePaidOut(newBalancePaidOut,);
      } 
    }
  );

  // const setReferralsToPayoutRequestedMutation = useMutation(apiServiceInstance.setUserReferralsPayoutRequested, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries([CacheKeys.REFERRALS_FROM_USER]);
  //     toast.success("Balance Claimed");
  //   },
  //   onError: (error: any) => {
  //     toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
  //   }
  // });  

  if ( isError_User || isError_UsersOffer ) {
    return <>error</>;
  }

  if (isLoading_User || isLoading_UsersOffer) {
    return null;
  }

  if(data_User && data_UsersOffer !== undefined ) {
    return (
      <>
      
      <Grid container direction="column" spacing={2}>

        <Grid item>
          <Card variant="outlined" style={{ margin: '0 auto' }}>
              <CardHeader 
                titleTypographyProps={{ variant:'h3'}}  
                title={`${data_User.givenName} ${data_User.familyName}`} 
                subheader={`${ !isDataEmpty(data_User.companyName) ? ` at ${data_User.companyName}` : ''}`}
                sx={{textAlign: 'center', backgroundColor: '#333', margin: '10px', padding: '5px' }}>
                
              </CardHeader>
              <CardContent sx={{textAlign: 'center'}}>

              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: 'nowrap', 
                          maxWidth: '200px', 
                        }}
                      >
                        <Typography variant="h5">Cash received:</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          pl: 2, 
                          textAlign: { xs: 'left', md: 'center' }, 
                        }}
                      >
                        <Typography variant="bigPrimary">£{balancePaidOut}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: 'nowrap',
                          maxWidth: '200px',
                        }}
                      >
                        <Typography variant="h5">Cash ready for transfer:</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          pl: 2,
                          textAlign: { xs: 'left', md: 'center' },
                        }}
                      >
                        <Typography variant="bigPrimary">£{balanceReadyToCashOut}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: 'nowrap',
                          maxWidth: '200px',
                        }}
                      >
                        <Typography variant="h5">Cash we're collecting:</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          pl: 2,
                          textAlign: { xs: 'left', md: 'center' },
                        }}
                      >
                        <Typography variant="bigPrimary">£{balanceWaitingCollection}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

                
              {
                balanceReadyToCashOut > 0 && (
                  <Box sx={{ margin: '10px', textAlign: 'center' }}>
                    {!isClaimed ? (
                      <motion.div
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }} // This triggers only during unmount
                        transition={{ duration: 0.5 }}
                        key="claim-button"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={() => {
                            claimBalanceMutation.mutate();
                          }}
                          disabled={claimBalanceMutation.isLoading}
                        >
                          Claim Balance
                        </Button>
                      </motion.div>
                    ) : (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }} // Message fades in after the button fades out
                        transition={{ duration: 0.5 }}
                        key="claim-message"
                      >
                        <Typography variant="h6" color="success.main">
                          Balance claimed! We will contact you when the cash is transferred to your account.
                        </Typography>
                      </motion.div>
                    )}
                  </Box>
  )
}
              
              <Box sx={{ margin: '10px', textAlign: 'center' }}>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  type="submit" 
                  size='large'
                  onClick={() => { setScreenSelection(usersScreenSelection.makeReferral) }}
                >
                  Make a referral
                </Button>
              </Box>

              </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Box 
            sx={{ 
              margin: '0px', 
              textAlign: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Half transparent black background
              //padding: '6px',
              borderRadius: '4px'
            }}
          >
          <Typography>Need help? Do you have any questions?</Typography>
          <Typography>Phone or text us on 07961808400</Typography>
        </Box>
        </Grid>  
        
          { data_User.companyAdmin == true &&
            <Grid item>
              <ReferralsAppOfferManagment/> 
            </Grid>
          }
        
        
          { data_User.companyAdmin == true  &&
            <Grid item>
              <RefferalsAppReferralsIn/>
            </Grid>
          }                 
        
        <Grid item>
            <RefferalsAppReferralsOut/>
        </Grid>

        

      <Box sx={{ margin: '10px', textAlign: 'center' }}>

      <Typography>Tell us about other businesses you trust</Typography>
    

        <Link
          href="#"
          underline="hover"
          variant="body1"
          onClick={(e) => {
            e.preventDefault();
            setScreenSelection(usersScreenSelection.makeRecommendation);
          }}
        >
          Recommend them here
        </Link>
      </Box>


        {/* { user && (user.sub == "auth0|642813a48864ae03763840a6" || user.sub == "auth0|6495c1e1fa9dddc06070f91d") &&
          <Grid item>
              <ReferralsAppAdmin setScreenSelection={setScreenSelection} />
          </Grid>
        } */}
      </Grid> 

      </>
    
    );
  }

  return <>no data...</>;

};

export default ReferralsAppHello;





