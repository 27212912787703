// analyticsUtils.ts

// If you’re using TypeScript, and you haven’t declared types for gtag or dataLayer yet, 
// you can extend the window interface or simply cast to `any` where necessary.

export function loadGoogleAnalytics(trackingId: string): void {
    // 1) Create the script tag for gtag.js
    const gaScript = document.createElement('script');
    gaScript.async = true;
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.head.appendChild(gaScript);
  
    // 2) Initialize the dataLayer and the gtag function
    // Note: We'll cast window to `any` to avoid TS errors if you haven't declared types for dataLayer.
    (window as any).dataLayer = (window as any).dataLayer || [];
    function gtag(...args: any[]) {
      (window as any).dataLayer.push(args);
    }
    (window as any).gtag = gtag;
  
    // 3) Configure GA
    gtag('js', new Date());
    gtag('config', trackingId);
  }
  