import React, {useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';



import { 
    Container, 
    Box,
    TextField, 
    Button, 
    FormControl, 
    FormHelperText,  
    InputLabel, 
    Select, 
    MenuItem, 
    Card, 
    CardContent, 
    Typography, 
    Grid, 
    CardHeader, 
    IconButton, 
    InputAdornment, 
    TableContainer,
    Table, TableBody, TableRow, TableCell, Paper  } from '@mui/material';


import { toast} from 'react-toastify';
import { useAPIUnauthenticatedService  } from '../helpers/APICallsUnauthenticated';
import { InHouseCardHeader, InHouseCardContent } from '../components/CustomMUI';
import { UserCreationAsBusinessOwnerAttributes, CacheKeys } from '../helpers/DataStructures';
import { motion, AnimatePresence } from "framer-motion"; 
import { useAuth0 } from '@auth0/auth0-react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import  DisplayOfferInGridWrapper  from '../components/DisplayOfferInGridWrapper';

enum FormState {
    FillOutForm,
    TellUserAboutRedirect
  }

  interface CreateUserAndOfferUnauthenticatedFormProps {
    onDone: () => void;
  }
  
const CreateUserAndOfferUnauthenticatedForm: React.FC<CreateUserAndOfferUnauthenticatedFormProps> = ({ onDone }) => {  

    const [currentFormState, setCurrentFormState] = useState(FormState.FillOutForm);

    const [offerData, setOfferData] = useState({      
        id: 0,
        theOffer: "",
        location: "Location",
        offeringFixedCashAmount: 50,
        startDateTime: '',
        endDateTime: '',
        user: { 
            givenName: '', 
            familyName: '', 
            profession: 'Profession', 
            companyName: 'Company Name',
            isMe: false,
            email: '',
            companyAdmin: true}
    });

    // useEffect(() => {
    //     if (currentFormState === FormState.FillOutForm) {
    //       setOfferData({
    //         id: 0,
    //         theOffer: "",
    //         location: "Location",
    //         offeringFixedCashAmount: 50,
    //         startDateTime: '',
    //         endDateTime: '',
    //         user: { 
    //           givenName: '', 
    //           familyName: '', 
    //           profession: 'Profession', 
    //           companyName: 'Company Name',
    //           isMe: false,
    //           email: '',
    //           wantsToMakeOffers: false
    //         }
    //       });
    //     }
    //   }, [currentFormState]);

    const [usersEmail, setUsersEmail] = useState("");

    const { loginWithRedirect, logout, isAuthenticated  } = useAuth0();

    const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm<UserCreationAsBusinessOwnerAttributes>({
        defaultValues: { offeringFixedCashAmount: 50 }
      });
    
    const queryClient = useQueryClient();

    const apiServiceInstance = useAPIUnauthenticatedService();

//   const { isLoading, isError, data: data_loggedInUser, error } = useQuery(
//     [CacheKeys.LOGGED_IN_USER], 
//     async () => {
      
//         const user = await apiServiceInstance.getAuthenticatedUser();
//         return user;      
//     }
//   ); 

  const { mutateAsync, isLoading, isError, isSuccess, error } = 
    useMutation([CacheKeys.LOGGED_IN_USER], apiServiceInstance.createUserAsBusinessOwner,
    {
        onSuccess: () => {
            queryClient.invalidateQueries([CacheKeys.LOGGED_IN_USER]);
            setCurrentFormState(FormState.TellUserAboutRedirect);

            setTimeout(() => {
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: 'signup',
                    login_hint: usersEmail, 
                  },
                  appState: {
                    returnTo: window.location.origin + '/dashboard',
                    redirectUri: window.location.origin + '/dashboard',
                  },
                });
              }, 2000);
        },
        onError: (error: any) => {
            
            toast.error(`An error occurred: ${error.response?.data?.message || error.message}`);
        }
    },
  );

  const onSubmit = async (data: UserCreationAsBusinessOwnerAttributes) => {

  

    data.companyAdmin = true; // business owner is always going to be company admin

    setUsersEmail(data.email);
    
    try {
      await mutateAsync(data);
    } catch (error) {
      console.error('Submission error:', error);
    }

    // try {        
    //     await mutateAsync({ ...data });

    //     setUsersEmail( data.email );
    //     onDone();
    // } catch (error: any) {
        
    // }

  };

  return (

    <AnimatePresence mode="wait">
    {currentFormState === FormState.TellUserAboutRedirect? (
        <motion.div
            key="redirecting"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3}}
        >
          Redirecting you to create a password for {usersEmail}
        </motion.div>
      ) : (
        <motion.div
            key="form"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3}}
        >

        <Container style={{  display: 'flex', justifyContent: 'center', alignItems: 'start', gap: '20px' }}>
              

        <Card variant="outlined" style={{ margin: '10px', padding: '10px', minWidth: '350px' }}>
            <InHouseCardHeader title="Welcome to In House" />
            <InHouseCardContent>      
                <Typography variant="cardSubtitle" component="div" style={{ marginBottom: '1em' }}>
                    Please provide your details...
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                
                                name="givenName"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'First name is required', maxLength: { value: 255, message: 'First name should be less than 255 characters'} }}
                                render={({ field }) => 
                                <TextField 
                                    {...field} 
                                    label="First Name" 
                                    error={Boolean(errors.givenName)} 
                                    helperText={errors.givenName?.message} 
                                    fullWidth
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setOfferData((prevData) => ({
                                          ...prevData,
                                          user: {
                                            ...prevData.user,
                                            givenName: e.target.value,
                                          },
                                        }));
                                      }}
                                />}
                            
                                
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="familyName"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Last name is required', maxLength: { value: 255, message: 'Last name should be less than 255 characters'} }}
                                render={({ field }) => 
                                <TextField 
                                    {...field} 
                                    label="Last Name" 
                                    error={Boolean(errors.familyName)} 
                                    helperText={errors.familyName?.message} 
                                    fullWidth
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setOfferData((prevData) => ({
                                          ...prevData,
                                          user: {
                                            ...prevData.user,
                                            familyName: e.target.value,
                                          },
                                        }));
                                      }}
                                />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="email" // Specify the field name
                                control={control}
                                defaultValue=""
                                rules={{
                                required: 'Email is required',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: 'Invalid email format',
                                },
                                maxLength: { value: 255, message: 'Email should be less than 255 characters' },
                                }}
                                render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Email"
                                    error={Boolean(errors.email)}
                                    helperText={errors.email?.message}
                                    fullWidth
                                />
                                )}
                            />
                            </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name="profession"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Profession is required' }}
                                render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="profession-label">Profession</InputLabel>
                                    <Select
                                        {...field}
                                        labelId="profession-label"
                                        label="Profession"
                                        error={Boolean(errors.profession)}
                                        onChange={(e) => {
                                            field.onChange(e); 
                                            setOfferData((prevData) => ({
                                                ...prevData,
                                                user: {
                                                  ...prevData.user,
                                                  profession: e.target.value, 
                                                },
                                              }));
                                          }}
                                    > 
                                    <MenuItem value="estate agent">Estate Agent</MenuItem>
                                    <MenuItem value="surveyor">Surveyor</MenuItem>
                                    <MenuItem value="conveyancer">Conveyencer</MenuItem>
                                    <MenuItem value="mortgage broker">Mortgage Broker</MenuItem>

                                    <MenuItem value="removal and storage">Removal and Storage</MenuItem>
                                    <MenuItem value="damp and timber">Damp and Timber</MenuItem>
                                    <MenuItem value="asbestos surveyors">Asbestos Surveyors</MenuItem>
                                    <MenuItem value="drain engineers">Drain Engineers</MenuItem>
                                    <MenuItem value="house cleaners">House Cleaners</MenuItem>  

                                    </Select>
                                    {errors.profession && <FormHelperText error>{errors.profession.message}</FormHelperText>}
      
                                    
                                </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                        <Controller
                                name="companyName"
                                control={control}
                                defaultValue="Company Name"
                                
                                rules={{ maxLength: { value: 255, message: 'Company name should be less than 255 characters'} }}
                                render={({ field }) => 
                                <TextField 
                                    {...field} 
                                    label="Company Name" 
                                    error={Boolean(errors.companyName)} 
                                    helperText={errors.companyName?.message} 
                                    fullWidth
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setOfferData((prevData) => ({
                                            ...prevData,
                                            user: {
                                              ...prevData.user,
                                              companyName: e.target.value, 
                                            },
                                          }));
                                      }}
                                />}
                            />
                        </Grid>

                        <Grid item xs={12}>

                            <Controller
                                name="offeringFixedCashAmount"
                                control={control}
                                rules={{ 
                                required: 'Cash amount is required',
                                min: {value: 0, message: "Can't offer less than zero"},
                                max: {value: 995, message: "Maximum amount is £995"} 
                                }}
                                render={({ field }) => (
                                
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField 
                                        {...field} 
                                        label="How much will you offer for a referral?" 
                                        error={Boolean(errors.offeringFixedCashAmount)} 
                                        helperText={errors.offeringFixedCashAmount?.message} 
                                        fullWidth 
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        }}
                                        
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setOfferData((prevData) => ({
                                            ...prevData,
                                            offeringFixedCashAmount: parseInt(e.target.value)
                                            }));
                                        }}
                                    />
                                    <IconButton 
                                        size="small"
                                        onClick={() => {
                                            const newValue = Math.min(getValues("offeringFixedCashAmount") + 5, 995);
                                            setValue("offeringFixedCashAmount", newValue);
                                            setOfferData((prevData) => ({
                                                ...prevData,
                                                offeringFixedCashAmount: newValue
                                                }));
                                        }}
                                        disabled={getValues("offeringFixedCashAmount") >= 995}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton 
                                    size="small"
                                    onClick={() => {
                                        const newValue = Math.max(getValues("offeringFixedCashAmount") - 5, 5);
                                        setValue("offeringFixedCashAmount", newValue);
                                        setOfferData((prevData) => ({
                                            ...prevData,
                                            offeringFixedCashAmount: newValue
                                            }));
                                    }}
                                    disabled={getValues("offeringFixedCashAmount") <= 5}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                    
                                </div>
                                
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name="location"
                                control={control}
                                defaultValue=""
                                
                                rules={{ 
                                    maxLength: { value: 255, message: 'Location should be less than 255 characters'}, 
                                    required: 'Area is required' 
                                }}
                                render={({ field }) => 
                                <TextField 
                                    {...field} 
                                    label="Location" 
                                    error={Boolean(errors.location)} 
                                    helperText={errors.location?.message} 
                                    fullWidth
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setOfferData((prevData) => ({
                                            ...prevData,
                                            location: e.target.value
                                          }));
                                      }}
                                />}
                            />
                        </Grid>

                            <Grid item xs={12}>                               
                                <Controller
                                    name="theOffer"
                                    control={control}
                                    defaultValue=""                                    
                                    rules={{ 
                                        maxLength: { value: 1000, message: 'offer should be less than 1000 characters'}
                                    }}
                                    render={({ field }) => 
                                    <TextField multiline rows={3} 
                                        {...field} 
                                        label="Services you provide" 
                                        error={Boolean(errors.theOffer)} 
                                        helperText={errors.theOffer?.message} 
                                        fullWidth
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setOfferData((prevData) => ({
                                                ...prevData,
                                                theOffer: e.target.value
                                            }));
                                        }}
                                    />}
                                />
                            </Grid>

                        <Grid item xs={12}  sx={{textAlign:'center'}}>
                            <Button variant='contained' type="submit" disabled={isLoading}>Submit</Button>
                        </Grid>
                    </Grid>
                </form>
            </InHouseCardContent>
        </Card>

        <Box 
            style={{ position: 'sticky', top: 0, padding: '5px',  zIndex: 1000, minWidth: '500px' }}        
            sx={{ display: { xs: 'none', md: 'block' } }}>  

            Preview of your listing...

            <TableContainer component={Paper} style={{ margin: '5px' }}>
            <Table>
            <TableBody>    
                
                <TableRow >
                    <TableCell colSpan={4}> 
                        <DisplayOfferInGridWrapper offer={offerData} onActionButtonClick={() => {}} />
                    </TableCell>
                </TableRow>           
            </TableBody>
            </Table>
            </TableContainer>
        </Box> 

        </Container>
         
        </motion.div>
        
      )}
    </AnimatePresence>
  );
};

export default CreateUserAndOfferUnauthenticatedForm
