import React, { useState } from 'react';
import { Grid, Box, IconButton, Typography, Hidden } from '@mui/material';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Cancel from '@mui/icons-material/CancelOutlined';

interface DisplayOfferInGridProps {
    //Name: JSX.Element;
    Company: string;
    Profession: string;
    CompanyLogo?: JSX.Element;
    Location: string;
    Offer: JSX.Element;
    ActionButton: JSX.Element;
  }

  export const DisplayOfferInGridBody: React.FC<DisplayOfferInGridProps> = ({ Company, Profession, CompanyLogo, Location, Offer, ActionButton }) => {
    return (

      <>
      <Grid container spacing={0} >
      {/* Mobile: 1st Column / Desktop: Combined 1st and 2nd Column */}
      <Grid item xs={6} sm={8} sx={{ }}>
        <Grid container item  direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
          <Grid item>   
            <Typography variant="bigPrimary"> {Company}</Typography>
          </Grid> 
          <Grid item>   
            {Profession ? (
                <>       
                  <Typography variant="body1">{Profession}</Typography><br/> 
                </>
              ) : (
                <>
                  &nbsp;<br/>             
                </>
              )}
          </Grid> 

          <Grid container >
            <Grid xs={6} item> 
                  &nbsp;
            </Grid>
            <Grid item container xs={6} justifyContent="center" alignItems="center">                                           
             <Typography variant='h4'> {Offer} </Typography> 
           </Grid> 

            <Grid container >
            <Grid xs={6} item>           
             
              {Location ? (
                <>       
                  <Typography variant='bigPrimary_body2'>Covering Area</Typography>
                  <br/>
                  {Location}
                </>
              ) : (
                <>
                <Typography variant='bigPrimary_body2'>&nbsp;</Typography>
                <br/>
                &nbsp;
                </>
              )}
            </Grid>
            <Grid item container xs={6} justifyContent="center" alignItems="center">                                           
              &nbsp;
           </Grid>  
          </Grid>  
                   
          </Grid>        
        </Grid>
      </Grid>

      {/* Mobile: 2nd Column
      <Hidden smDown>
        <Grid container item sm={2} alignItems="center" justifyContent="center">
            <Typography variant='h4'>{Offer}</Typography>
        </Grid>
      </Hidden>       */}

      <Grid container item xs={6} sm={4} direction="column" justifyContent={CompanyLogo ? "space-between" : "center"} >
        
        {CompanyLogo && (

          <Grid item>

            {CompanyLogo}

          </Grid>

        )}

            <Grid item container alignItems="center" justifyContent="center">             
                  {ActionButton}
            </Grid> 
      </Grid>

      
      </Grid>

    </>


    
    )}

    interface DisplayOfferInGridHeaderProps {
      //Name: JSX.Element;
      Company: JSX.Element;
      CompanyLogo?: JSX.Element;
      Location: JSX.Element;
      Offer: JSX.Element;
      ActionButton: JSX.Element;
    }

export const DisplayOfferInGridHeader: React.FC<DisplayOfferInGridHeaderProps> = ({ Company, Location, Offer, ActionButton }) => {
    
  const [isGridVisible, setGridVisible] = useState(false);

  const handleVisibilityToggle = () => {
    setGridVisible(prev => !prev);
  };

  return (
    <>
     
        <Grid container spacing={0} >
          <Grid item xs={6} style={{ padding: isGridVisible ? '10px' : '0px' }}>
          {isGridVisible && (
            <Grid container spacing={0}>                        
            <Grid item xs={12}>              
              {Company} 
            </Grid>

            <Grid item xs={12}>
              {Location}
            </Grid> 
            </Grid>
          )} 
          </Grid>

          <Grid item xs={3} >
          {isGridVisible && (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">                               
              {Offer}                            
            </Box> 
          )}
          </Grid>

          <Grid item xs={3} style={{ paddingRight: '16px' }}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">           
                <IconButton onClick={handleVisibilityToggle}>
                  {!isGridVisible ? <><ArrowDownward /><ArrowUpward /></> : <Cancel /> }
                </IconButton>        
            </Box>                        
          </Grid>
        </Grid>    
    </>
  );
}
