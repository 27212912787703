


export function translateFromTechnicalToEnglish(word: string): string {

    const dictionary: {[key: string]: string} = {
        "created": "Sent",
        "succeeded": "Successful",
        "failed": "Unsuccessful",
        "payoutrequested": "Balance Requested",
        "estate agents": "Estate Agents",
        "mortgage brokers": "Mortgage Brokers",
        "surveyors": "Surveyors",
        "conveyancers": "Conveyancers",
        "estate agent": "Estate Agent",
        "mortgage broker": "Mortgage Broker",
        "surveyor": "Surveyor",
        "conveyancer": "Conveyancer",
        "removal and storage": "Removal and Storage",
        "damp and timber": "Damp and Timber",
        "asbestos surveyors": "Asbestos Surveyors",
        "drain engineers": "Drain Engineers",
        "house cleaners": "House Cleaners"
    };

    const lowerCaseWord = word.toLowerCase();

    if (lowerCaseWord in dictionary) {
        return dictionary[lowerCaseWord];
    } else {
        return word;
    }
}

//@IsIn([['created', 'goingAhead', 'succeeded', 'failed', 'alreadyaCustomer', 'payoutCollected', 'payoutSent']])


export function translateFromStatusToShortTextForOfferer(word: string): string {

    const dictionary: {[key: string]: string} = {
        "created": "New Referral",
        "goingAhead": "Proceeding with Job",
        "succeeded": "Job done",
        "failed": "Not doing this Job",
        "alreadyaCustomer": "Already a customer",
        "payoutCollected": "Paid Referral Fee",
        "payoutSent": "Paid Referral Fee"
    };

    //const lowerCaseWord = word.toLowerCase();

    if (word in dictionary) {
        return dictionary[word];
    } else {
        return word;
    }
}

export function translateFromStatusToShortTextForReferrer(word: string): string {

    const dictionary: {[key: string]: string} = {
        "created": "Sent Referral",
        "goingAhead": "Job is going ahead",
        "succeeded": "Job Done",
        "failed": "Referral was unsuccessful",
        "alreadyaCustomer": "Already a customer",
        "payoutCollected": "Fee has been collected",
        "payoutSent": "Fee has been paid"
    };

    //const lowerCaseWord = word.toLowerCase();

    if (word in dictionary) {
        return dictionary[word];
    } else {
        return word;
    }
}

export function translateFromStatusToSentenceOfferer(word: string): string {

    const dictionary: {[key: string]: string} = {
        "created": "What happens next: Contact this customer and click the button below to let us know if you won this work",
        "goingAhead": "What happens next: Click the button below to let us know when you have completed this job",
        "succeeded": "What happens next: We will send you an invoice to collect the referral fee",
        "failed": "No referral fee to pay",
        "alreadyaCustomer": "No referral fee to pay",
        "payoutCollected": "We have collected the fee for this referral",
        "payoutSent": "We have collected the fee for this referral"
    };

    //const lowerCaseWord = word.toLowerCase();

    if (word in dictionary) {
        return dictionary[word];
    } else {
        return word;
    }
}

export function translateFromStatusToSentenceReferrer(word: string): string {

    const dictionary: {[key: string]: string} = {
        "created": "What happens next: The customer will be contacted about the job",
        "goingAhead": "What happens next: We will update when the job is finished",
        "succeeded": "What happens next: We will send an invoice to collect the referral fee for you and ad it to your balance",
        "failed": "Unfortunately the customer didn't go ahead",
        "alreadyaCustomer": "Unfortunately the business already has this person as an existing customer",
        "payoutCollected": "We have collected the referral fee for you - it's showing in your balance",
        "payoutSent": "We have collected the referral fee for you and have transferred the money to your account"
    };

    //const lowerCaseWord = word.toLowerCase();

    if (word in dictionary) {
        return dictionary[word];
    } else {
        return word;
    }
}
  